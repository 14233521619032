import React, { useEffect, useState } from "react";
import { Drawer, Modal, Dropdown, DatePicker } from "antd";
import { Select } from "antd";
import { CiClock2 } from "react-icons/ci";
import ChartData from "./ChartData";
import { FiMoreHorizontal } from "react-icons/fi";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCloseKeyresultDrawer,
  handleCloseUpdateKeyResultModal,
  handleOpenCopyKeyResultModal,
  handleOpenMoveKeyResultModal,
  handleOpenUpdateKeyResultModal,
} from "../../stores/slices/okrSlice";
import { getAvatarColor, getInitials, getUnitValue } from "../../utils/helpers";
import UpdateKeyResultDrawer from "./UpdateKeyResultModal";
import UpdateKeyResultCard from "./UpdateKeyResultCard";
import { BiGlobe } from "react-icons/bi";
import MoveKeyResultModal from "./Move/MoveKeyResultModal";
import CopyKeyResultModal from "./Copy/CopyKeyResultModal";
import KeyComments from "./Comments/KeyComments";
import UpdateKeyResultComments from "./Comments/UpdateKeyResultComments";
import { api } from "../global/api";

export const KeyResultDrawer = ({
  open,
  keyResultDataKey,
  getOkrs,
  getOkrData,
  owners,
}) => {
  const [keyResultData, setKeyResultData] = useState();
  const [selectedOwner, setSelectedOwner] = useState([]);
  const okrSlice = useSelector((state) => state.okr);
  const dispatch = useDispatch();
  const usernew = useSelector((state) => state.user);
  const roleAccess = usernew?.roleAccess;
  const currentUser = usernew?.currentUser;
  const currentEmployee = currentUser?.employees[usernew?.currentCompany];
  const [formData, setformData] = useState({
    name: "",
    parent_team: "",
    manager: "",
    tags: "",
    deadline: "",
    description: "",
    comments: "",
  });
  const [updateValues, setUpdateValues] = useState();
  const [update_key_id, setUpdate_key_id] = useState("");
  useEffect(() => {
    if (keyResultData?.owners?.length) {
      let tempOwnerList = keyResultData?.owners.map(
        (owner) => owner?.id + " " + owner?.type
      );
      setSelectedOwner(tempOwnerList);
    } else {
      setSelectedOwner([]);
    }
  }, [keyResultData]);

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const onChangeTimePicker = (date) => {
    setformData({ ...formData, deadline: date });
  };
  const getKeyResuktsData = async () => {
    try {
      const res = await api.get(baseURL + "/key_results/", {
        key_id: okrSlice?.openKeyResultsDrawer,
        company_id: currentEmployee?.company_id,
      });
      setKeyResultData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUpdateValues = async () => {
    try {
      const res = await api.get(baseURL + `/update-key/`, {
        key_id: okrSlice?.openKeyResultsDrawer,
      });
      setUpdateValues(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (okrSlice?.openKeyResultsDrawer || keyResultDataKey) {
      getOkrs();
      getKeyResuktsData();
      getUpdateValues();
    }
    // getKeyResuktsData()
  }, [okrSlice?.openKeyResultsDrawer, keyResultDataKey, open]);
  useEffect(() => {
    if (keyResultData) {
      setformData({
        ...keyResultData,
      });
    }
  }, [keyResultData]);

  const dataUpdated = () => {
    getOkrs();
    getKeyResuktsData();
    getUpdateValues();
    getOkrData(keyResultData?.okr_id);
  };
  const putKeyResult = async (e) => {
    if (!roleAccess?.["Key-Results"]?.Update) {
      
      return;
    }
    try {
      let user_id = [];
      let team_id = [];
      let changeData = {};
      if (e) {
        if (!e.target.value) return;
        changeData = {
          [e.target.name]:
            e.target.name === "deadline"
              ? formData?.deadline?.$d?.toISOString()
              : e.target.value,
        };
      } else {
        selectedOwner?.forEach((item) => {
          let typeArr = item.split(" ");
          if (typeArr[1] === "user") {
            user_id.push(typeArr[0]);
          } else if (typeArr[1] === "team") {
            team_id.push(typeArr[0]);
          }
        });
        changeData = {
          user_id,
          team_id,
        };
      }

      const res = await api.put(baseURL + `/key_results/`, {
        key_id: keyResultData?.key_id,
        company_id: currentEmployee?.company_id,
        ...changeData,
      });
      dataUpdated();
    } catch (error) {
      console.log(error);
    }
  };

  const formattedDeadline = keyResultData?.deadline
    ? new Date(keyResultData?.deadline).toISOString().split("T")[0]
    : null;

  const updateSelectedOwner = async (e) => {
    let user_id = [];
    let team_id = [];
    selectedOwner?.forEach((item) => {
      let typeArr = item?.split(" ");
      if (typeArr[1] === "user") {
        user_id.push(typeArr[0]);
      } else {
        team_id.push(typeArr[0]);
      }
    });

    const currentEmployee = currentUser?.employees[usernew?.currentCompany];
    try {
      if (e) {
        let typeArr = e?.split(" ");
        if (typeArr[1] === "user") {
          user_id = user_id.filter((id) => id !== typeArr[0]);
        } else {
          team_id = team_id.filter((id) => id !== typeArr[0]);
        } // Filter out e from team_id
      }

      const res = await api.put(baseURL + `/key_results/`, {
        key_id: keyResultData?.key_id,
        company_id: currentEmployee?.company_id,
        ...{ user_id, team_id },
      });
      dataUpdated();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteKeyResult = async () => {
    try {
      const res = await api.delete(baseURL + `/key_results/`, {
        key_id: keyResultData?.key_id,
        company_id: currentEmployee?.company_id,
      });
      dataUpdated();
      dispatch(handleCloseKeyresultDrawer());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        width={1000}
        onClose={() => dispatch(handleCloseKeyresultDrawer())}
        open={okrSlice?.openKeyResultsDrawer || open}
        zIndex={2}
        // title=",s,s"
        title={
          <DrawerHeader
            handleDeleteKeyResult={handleDeleteKeyResult}
            roleAccess={roleAccess}
          />
        }
      >
        <div>
          <div className="relative flex items-center p-0 px-5 m-2 mb-20 gap-7 ">
            <div className="flex items-center w-full gap-3 px-2 py-1 border border-blue-300 rounded">
              <button>
                <img
                  src="/assets/images/keyresults.svg"
                  style={{
                    width: "27px",
                    height: "27px",
                  }}
                />
              </button>

              <input
                className="flex items-center w-3/5 px-2 py-1 text-sm border border-gray-300 border-none rounded"
                name="title"
                id="title"
                onChange={changeHandeler}
                value={formData?.title}
                onBlur={putKeyResult}
                maxLength={70}
                readOnly={!roleAccess?.["Key-Results"]?.Update}
                // placeholder="Enter team Name"
                required
              />
            </div>

            <button
              className={`px-4 py-1.5 ml-32 text-white bg-blue-500 rounded-full uppercase font-semibold
    ${
      !roleAccess?.["Key-Results"]?.Update
        ? "opacity-50 cursor-not-allowed"
        : "hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 cursor-pointer"
    }
  `}
              onClick={() => dispatch(handleOpenUpdateKeyResultModal(true))}
              disabled={!roleAccess?.["Key-Results"]?.Update}
            >
              {" "}
              Update
            </button>
          </div>
        </div>

        {/* parent div */}

        <div className="flex flex-row ">
          {/* div -1 part-1 */}

          <div className="div-left  min-w-[469px] p-0 px-15 flex-1">
            <form action="">
              <div className="flex items-center p-1 mb-2 min-h-40 gap-y-6 ">
                <div className="flex-shrink-0 w-1/4 text-base leading-4 text-51618a cursor-defaul">
                  <label className="flex-shrink-0 w-1/4 text-sm leading-4 cursor-default text-51618a">
                    Owner{" "}
                  </label>
                </div>
                <div className="flex flex-1 min-w-0 text-base leading-5 text-384758">
                  <Select
                    className="w-4/5"
                    mode="multiple"
                    value={selectedOwner}
                    size="large"
                    removeIcon={selectedOwner.length !== 1 ? undefined : ""}
                    onChange={(e) => {
                      setSelectedOwner(e);
                    }}
                    onBlur={(e) => {
                      putKeyResult();
                    }}
                    onDeselect={(e) => {
                      updateSelectedOwner(e);
                    }}
                  >
                    {owners?.map((owner, index) => {
                      return (
                        <Select.Option
                          value={owner?.id + " " + owner?.type}
                          key={index}
                        >
                          <div className="flex items-center gap-x-2">
                            {owner?.type === "user" ? (
                              <div
                                className="w-5 h-5 border text-sm border-black rounded-full flex items-center justify-center text-white relative"
                                style={{
                                  backgroundColor: owner?.profile_image
                                    ? "initial"
                                    : getAvatarColor(owner?.name),
                                }}
                                size={18}
                              >
                                {owner?.profile_image ? (
                                  <img
                                    src={owner.profile_image}
                                    alt="Profile"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      display: "block",
                                    }}
                                  />
                                ) : (
                                  getInitials(owner.name)
                                )}
                              </div>
                            ) : (
                              <span className="p-1 bg-gray-600 rounded-full">
                                <BiGlobe className="text-gray-300 text-xs" />
                              </span>
                            )}
                            <span>{owner.name}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="flex items-start p-1 mb-2 min-h-40">
                <div className="flex-shrink-0 w-1/4 text-base leading-4 text-51618a cursor-defaul">
                  <label
                    htmlFor=""
                    className="flex-shrink-0 w-1/4 text-sm leading-4 cursor-default text-51618a"
                  >
                    Description
                  </label>
                </div>
                <div className="flex flex-1 min-w-0 text-base leading-5 text-384758">
                  <textarea
                    rows={4}
                    cols={40}
                    placeholder="Add Description"
                    width="4/5"
                    name="description"
                    id="description"
                    maxLength={150}
                    className="w-4/5 px-2 py-1 text-sm border border-gray-300 rounded-lg"
                    onChange={changeHandeler}
                    onBlur={putKeyResult}
                    value={formData?.description || keyResultData?.description}
                    readOnly={!roleAccess?.["Key-Results"]?.Update}
                  />
                </div>
              </div>

              <div className="flex items-center p-1 mb-2 min-h-40">
                <div className="flex-shrink-0 w-1/4 text-base leading-4 text-51618a cursor-defaul">
                  <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                    Initial
                  </label>
                </div>
                <div className="flex flex-1 min-w-0 text-base leading-5 text-384758">
                  <input
                    className="w-4/5 px-2 py-1 text-sm border border-gray-300 rounded-lg"
                    placeholder={
                      getUnitValue(keyResultData?.unit) !== "%"
                        ? getUnitValue(keyResultData?.unit) +
                          keyResultData?.initial_number
                        : keyResultData?.initial_number +
                          getUnitValue(keyResultData?.unit)
                    }
                    // type="number"
                    name="initial_number"
                    id="initial_number"
                    onChange={changeHandeler}
                    readOnly={!roleAccess?.["Key-Results"]?.Update}
                    type="number"
                    onFocus={(e) => {
                      e.target.value = keyResultData?.initial_number;
                    }}
                    onBlur={(e) => {
                      putKeyResult(e);
                      e.target.value = null;
                    }}
                  ></input>
                </div>
              </div>

              <div className="flex items-center p-1 mb-2 min-h-40">
                <div className="flex-shrink-0 w-1/4 text-base leading-4 text-51618a cursor-defaul">
                  <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                    Actual Value
                  </label>
                </div>
                <div className="flex flex-1 min-w-0 text-base leading-5 text-384758">
                  <input
                    className="w-4/5 px-2 py-1 text-sm border border-gray-300 rounded-lg"
                    placeholder={
                      getUnitValue(keyResultData?.unit) !== "%"
                        ? getUnitValue(keyResultData?.unit) +
                          keyResultData?.current_number
                        : keyResultData?.current_number +
                          getUnitValue(keyResultData?.unit)
                    }
                    readOnly={!roleAccess?.["Key-Results"]?.Update}
                    onClick={(e) => {
                      e.target.focus = null;
                      if (roleAccess?.["Key-Results"]?.Update) {
                        dispatch(handleOpenUpdateKeyResultModal(true));
                      }
                    }}
                    // type="number"
                  ></input>
                </div>
              </div>
              <div className="flex items-center p-1 mb-2 min-h-40">
                <div className="flex-shrink-0 w-1/4 text-base leading-4 text-51618a cursor-defaul">
                  <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                    Target
                  </label>
                </div>
                <div className="flex flex-1 min-w-0 text-base leading-5 text-384758">
                  <input
                    className="w-4/5 px-2 py-1 text-sm border border-gray-300 rounded-lg"
                    placeholder={
                      keyResultData?.key_result_type +
                      " " +
                      (getUnitValue(keyResultData?.unit) !== "%"
                        ? getUnitValue(keyResultData?.unit) +
                          keyResultData?.target_number
                        : keyResultData?.target_number +
                          getUnitValue(keyResultData?.unit))
                    }
                    type="number"
                    name="target_number"
                    id="target_number"
                    readOnly={!roleAccess?.["Key-Results"]?.Update}
                    onChange={changeHandeler}
                    onFocus={(e) => {
                      e.target.value = keyResultData?.target_number;
                    }}
                    onBlur={(e) => {
                      putKeyResult(e);
                      e.target.value = null;
                    }}

                    // value={}
                  ></input>
                </div>
              </div>

              <div className="flex items-center p-1 mb-2 min-h-40">
                <div className="flex-shrink-0 w-1/4 text-base leading-4 text-51618a cursor-defaul">
                  <label
                    htmlFor=""
                    className="box-border h-full overflow-y-auto text-left break-words whitespace-pre-wrap outline-none line-height-142 tab-4"
                  >
                    Update Deadline
                  </label>
                </div>
                <div className="flex flex-1 min-w-0 text-base leading-5 text-384758">
                  <DatePicker
                    id="deadline"
                    name="deadline"
                    className=" border-gray-300 "
                    size=" "
                    placeholder={formattedDeadline}
                    disabled={!roleAccess?.["Key-Results"]?.Update}
                    onChange={onChangeTimePicker}
                    onBlur={(e) => putKeyResult(e)}
                    style={{
                      width: "80%",
                    }}
                  />
                </div>
              </div>
            </form>

            {/* div-1 part 2 */}

            <div className="flex flex-col p-2 m-2">
              <div className="flex flex-col items-start mt-10 mb-0 ">
                <div className="flex flex-col gap-y-4 w-11/12">
                  <KeyComments key_id={keyResultData?.key_id} />
                </div>
              </div>
            </div>
          </div>

          <div className="graph-div items-center min-w-[469px] p-0 px-15 flex-1">
            {/* graph */}

            <div className="w-full">
              <ChartData
                updateValues={updateValues}
                keyResultData={keyResultData}
              />
            </div>

            <div className="w-full mt-10 text-center border border-gray-200 rounded">
              {updateValues?.length ? (
                updateValues?.map((iteam, key) => (
                  <UpdateKeyResultCard
                    iteam={iteam}
                    index={key}
                    dataUpdated={dataUpdated}
                    setUpdate_key_id={setUpdate_key_id}
                  />
                ))
              ) : (
                <div className="w-full mt-10 text-center">
                  <div className="flex flex-col items-center justify-center">
                    <CiClock2
                      className="mb-2 text-d8dfea"
                      size={150}
                      style={{ color: "#E8E8E8" }}
                    />
                    <h2 className="font-medium text-slate-500 text-base  tracking-wide text-1.6 max-w-xs mx-auto mb-2 text-66849c">
                      This Key Result has never been updated.
                    </h2>
                  </div>

                  <div>
                    <p className="text-sm text-slate-400">
                      Even if there is no progress, you can post an update.
                    </p>
                    <h3 className="mt-3 text-lg text-blue-600 cursor-pointer ">
                      <div
                        className="mb-4"
                        onClick={() =>
                          dispatch(handleOpenUpdateKeyResultModal(true))
                        }
                      >
                        {" "}
                        + Post an update{" "}
                      </div>{" "}
                    </h3>
                  </div>
                </div>
              )}

              {/* end of the page */}
            </div>
          </div>
        </div>
      </Drawer>
      <Modal
        width={650}
        style={{ top: 20 }}
        open={okrSlice?.updateKeyResultModal}
        footer={null}
        onCancel={() => dispatch(handleCloseUpdateKeyResultModal())}
      >
        <UpdateKeyResultDrawer
          keyResultData={keyResultData}
          onCancel={() => dispatch(handleCloseUpdateKeyResultModal())}
          dataUpdated={dataUpdated}
        />
      </Modal>
      <MoveKeyResultModal drawerData={keyResultData} getOkrs={getOkrs} />
      <CopyKeyResultModal drawerData={keyResultData} getOkrs={getOkrs} />
      <UpdateKeyResultComments
        update_key_id={update_key_id}
        onClose={() => setUpdate_key_id()}
        title={keyResultData?.title}
      />
    </>
  );
};

const DrawerHeader = ({ handleDeleteKeyResult, key_id, roleAccess }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center justify-end p-1 gap-x-2">
      <Dropdown
        menu={{
          items: [
            //   {
            //   key: '6',
            //   label: (
            //     <div target="_blank" rel="noopener noreferrer" >
            //       Copy URL
            //     </div>
            //   ),
            // },
            {
              label: "Move",
              key: "7",
              onClick: () =>
                dispatch(
                  handleOpenMoveKeyResultModal({
                    id: key_id,
                    type: "key_result",
                  })
                ),
            },
            {
              label: "Clone",
              key: "8",
              onClick: () =>
                dispatch(
                  handleOpenCopyKeyResultModal({
                    id: key_id,
                    type: "key_result",
                  })
                ),
            },
            roleAccess?.["Key-Results"]?.Delete && {
              label: "Delete",
              key: "9",
              danger: true,
              onClick: handleDeleteKeyResult,
            },
          ],
        }}
      >
        <button className="p-2 transition-colors -rotate-90 rounded hover:bg-slate-100">
          <FiMoreHorizontal className="text-xl" />
        </button>
      </Dropdown>
    </div>
  );
};
