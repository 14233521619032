import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import ExecutiveInsights from "./ExecutiveInsights";
import StrategicReport from "./StrategicReport";
import ReportDrafts from "../Report/ReportDrafts";
import CreateReportModal from "../../components/Report/CreateReportModal";
import { handleCloseCreateReport } from "../../stores/slices/okrSlice";

const Insights = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Executive Insights");

  const handleClose = () => {
    dispatch(handleCloseCreateReport());
  };

  const fetchData = () => {
    setSelectedTab("Reports");
  };

  

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "Insights",
        ClickLink: `Insight / ${selectedTab}`,
      })
    );
  }, [dispatch, selectedTab]);

  const tabs = ["Executive Insights", "Strategic Report", "Reports"];

  return (
    <div className="relative h-screen p-4">
      <div className="flex items-center space-x-16 px-5">
        {tabs?.map((tab) => (
          <h1
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`relative cursor-pointer ${
              selectedTab === tab ? "text-orange-500 font-sans" : ""
            }`}
          >
            {tab}
            {selectedTab === tab && (
              <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
            )}
          </h1>
        ))}
      </div>
      <div className="border-b-2 border-gray-300 mb-2 mt-2"></div>

      <div
        className="flex flex-col p-4 overflow-y-hidden"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        {selectedTab === "Executive Insights" && <ExecutiveInsights />}
        {selectedTab === "Strategic Report" && <StrategicReport />}
        {selectedTab === "Reports" && <ReportDrafts />}
      </div>
      <CreateReportModal onCancel={handleClose} fetchData={fetchData} />
    </div>
  );
};

export default Insights;
