import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import {
  getAvatarColor,
  getInitials,
} from "../../../utils/helpers";
import { MdGroups } from "react-icons/md";
import CustomScrollbar from "../../global/CustomScrollBar";

export const Tables = ({ data, fetchData, handleDeleteEmployee }) => {
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;

  return (
    <div className="rounded-lg h-full">
      <div className="mx-auto p-3">
        <CustomScrollbar>
          <table className="min-w-full bg-white overflow-y-hidden p-6 rounded-lg">
            <thead
              className="border-b text-left bg-header text-white rounded-lg"
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "8px",
              }}
            >
              <tr className="rounded" style={{ textAlign: "center" }}>
                <th className="text-left px-10 py-2">Name</th>
                <th className="text-left px-10 py-2">Role</th>
                <th className="text-left px-10 py-2">Team</th>
                <th className="py-2">Actions</th>
              </tr>
            </thead>
            <tbody className="overflow-y-scroll p-8">
              {data?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className=" transition-all  rounded hover:cursor-pointer py-4  text-center m-4 border border-b "
                  >
                    <td className="py-2 text-center flex px-8 justify-start items-center ">
                      <div className="flex items-center gap-x-2 py-2 ">
                        <div
                          className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                          style={{
                            backgroundColor: item?.profile_image
                              ? "initial"
                              : getAvatarColor(item?.username),
                          }}
                          size={30}
                        >
                          {item?.profile_image ? (
                            <img
                              src={item?.profile_image}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                                display: "block",
                              }}
                            />
                          ) : (
                            getInitials(item?.username)
                          )}
                        </div>
                        {item?.username}
                      </div>
                    </td>
                    <td className="py-2 text-center">
                      <div className="flex w-full pt-3 pb-3">
                        {item?.rolename}
                      </div>
                    </td>
                    <td className="py-2">
                      <div className="flex w-full pt-3 pb-3">
                        <ul className="h-full flex flex-col">
                          {item?.teams?.map((team, index) => (
                            <li
                              className="flex gap-x-3 text-sm hover:text-gray-500 transition-all mb-0.5"
                              key={index}
                              style={{ width: "100%" }}
                            >
                              <span
                                className="p-0.5 rounded-full"
                                style={{ backgroundColor: "#063F5D" }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #fff",
                                    borderRadius: "100vh",
                                    padding: "3px",
                                  }}
                                >
                                  <MdGroups className="text-white" size={14} />
                                </div>
                              </span>
                              <p
                                className="font-medium"
                                style={{
                                  color: team?.isManager
                                    ? "#612BD1"
                                    : "#BB1D72",
                                }}
                              >
                                {team?.team_name}
                                {/* {team?.isManager ? ", (Manager)" : ""} */}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </td>

                    <td className="py-2 text-center">
                      <div className="">
                        <Tooltip>
                          <button
                            onClick={() => {
                              handleDeleteEmployee(item?.employee_id);
                            }}
                            className="bg-white px-2 transition-all rounded-lg"
                            disabled={item?.rolename === "Admin"}
                          >
                            <svg
                              width="16"
                              height="18"
                              viewBox="0 0 16 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`${
                                item?.rolename === "Admin"
                                  ? "text-red-200 cursor-not-allowed"
                                  : "text-red-600 cursor-pointer"
                              }`}
                            >
                              <path
                                d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CustomScrollbar>
      </div>
    </div>
  );
};
