import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import { IoIosArrowRoundForward } from "react-icons/io";
import TemplateModal from "./TemplateModal";
import { baseURL } from "../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import { useDisclosure } from "@chakra-ui/react";
import ConfirmationModal from "./ConfirmationModal";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import { handleOpenScheduleModal } from "../../stores/slices/okrSlice";
import { api }  from "../../components/global/api";

const TouchbaseTemplates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state.user);
  const [formData, setformData] = useState();
  const currentPlan = user?.currentPlan;
  const okrSlice = useSelector((state) => state?.okr);

  if (currentPlan?.plan_name === "Free" && !currentPlan?.free_trial_status) {
    navigate("/company/billing");
  }

  const TemplateCards = ({
    title,
    template,
    data,
    description,
    stroke,
    primaryColor,
    secondaryColor,
    handleTemplate,
    handleDeleteTemplate,
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div
        className="bg-white p-4 rounded-xl min-h-[240px] relative cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() =>
          handleTemplate({
            title,
            stroke,
            template,
            primaryColor,
            secondaryColor,
            data,
          })
        }
      >
        <div
          style={{
            position: "absolute",
            bottom: "-3px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "60%",
            height: "5px",
            backgroundColor: secondaryColor,
            borderRadius: "4px",
            background: `linear-gradient(0deg, ${primaryColor} 0%, ${secondaryColor} 50%, ${secondaryColor} 70%, ${primaryColor} 100%)`,
          }}
        ></div>

        <div
          className="bg-[#FAFAFA]  p-4 min-h-[260px] flex flex-col justify-evenly"
          style={
            isHovered
              ? {
                  background: `linear-gradient(225deg, ${primaryColor} 0%, ${secondaryColor} 50%, ${secondaryColor} 70%, ${primaryColor} 100%)`,
                }
              : {}
          }
        >
          <p className="flex justify-end">
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.2057 3.16602H3.16406V20.5827H18.2057V3.16602ZM34.8307 26.916H3.16406V34.041H34.8307V26.916ZM34.8307 3.16602H24.5391V9.49935H34.8307V3.16602ZM34.8307 14.2493H24.5391V20.5827H34.8307V14.2493Z"
                stroke={stroke}
                strokeWidth="3"
                strokeLinejoin="round"
              />
            </svg>
          </p>
          <div className="flex flex-col space-y-2 h-[160px]">
            <h3 className="font-poppins font-semibold  text-lg text-[#3D3B36]">
              {title}
            </h3>
            <p className="text-[#979795]">{description}</p>
          </div>
          <div className="flex justify-between mt-10">
            <button
              className={`flex justify-start rounded-tl-xl items-center transition-all duration-300 ${
                isHovered ? "group" : ""
              }`}
            >
              <IoIosArrowRoundForward
                className={`transform transition-transform duration-300 ${
                  isHovered ? "translate-x-2" : ""
                }`}
                size={30}
              />
            </button>
            {!template?.default_temp && (
              <button
                className={`flex  rounded-tl-xl items-center transition-all duration-300 ${
                  isHovered ? "group" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteTemplate(template);
                }}
              >
                <AiOutlineDelete size={20} />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "TouchBase",
        ClickLink: "Touchbase / Schedule",
      })
    );
  });

  const [templateModal, setTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenModal = (template) => {
    setSelectedTemplate(template);
    onOpen();
  };

  const handleConfirmDelete = () => {
    if (selectedTemplate) {
      handleDeleteTemplate(selectedTemplate);
      setSelectedTemplate(null);
      onClose();
    }
  };


  const handleSchedule = () => {
    dispatch(handleOpenScheduleModal(true));
  };

  const handleClose = async () => {
    setformData((prevData) => ({
      ...prevData,
      template: null,
      name: "",
      Cadence_schedule: null,
      parent_val: null,
      participations: [],
      recurrence: "one_time",
    }));

    dispatch(handleOpenScheduleModal(false));
  };

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTemplate = (templateData) => {
    setSelectedTemplate(templateData);
    setTemplateModal(true);
  };

  const fetchData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await api.get(baseURL + "/template/", {
        company_id: currentEmployee?.company_id,
      });
      setTemplates(response?.data);

      setLoading(false);
    } catch (error) {
      console.error(error);

      setLoading(false);
    }
  };

  const handleUpDateTemplate = async (payload) => {
    try {
      const response = await api.put(baseURL + "/template/", payload);
      fetchData();
      toast.success("Template Updated ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
      toast.error("Try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    fetchData();
    setformData((prevData) => ({
      ...prevData,
      template: null,
      name: "",
      Cadence_schedule: null,
      parent_val: null,
      participations: [],
      recurrence: "one_time",
    }));
  }, [currentUser, user]);

  const handleDeleteTemplate = async (template) => {
    const currentEmployee = currentUser?.employees[user?.currentCompany];
    try {
      const res = await api.delete(baseURL + `/template/`, {
          template_id: template?.id,
      });
      fetchData();
      toast.success("Template Deleted Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
      toast.error("Try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="relative h-screen p-4">
      <p className="font-lato text-[#121213] font-semibold text-xl mb-2">
        All Templates
      </p>
      <div
        className="flex flex-col p-2"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        <div className="p-2 h-full rounded-lg">
          <CustomScrollbar>
            <div className="flex flex-col w-full p-2">
              {loading ? (
                <p>Loading templates...</p>
              ) : templates?.length > 0 ? (
                <div className="grid grid-cols-3 gap-3">
                  {templates?.map((template, index) => {
                    let stroke, primaryColor, secondaryColor;

                    if (template?.template_type === "quarterly_template") {
                      stroke = "#92CAC1";
                      primaryColor = "#F7FFFE";
                      secondaryColor = "#CEF7F0";
                    } else if (template?.template_type === "monthly_template") {
                      stroke = "#F4DB77";
                      primaryColor = "#FFFDF7";
                      secondaryColor = "#FFF1B8";
                    } else {
                      stroke = "#EEB0B0";
                      primaryColor = "#FFF8F8";
                      secondaryColor = "#FFE0E0";
                    }

                    return (
                      <React.Fragment key={template?.id}>
                        <TemplateCards
                          title={template?.template_title}
                          description={template?.description}
                          data={template?.questions}
                          template={template}
                          stroke={stroke}
                          primaryColor={primaryColor}
                          secondaryColor={secondaryColor}
                          handleTemplate={handleTemplate}
                          handleDeleteTemplate={() => handleOpenModal(template)}
                        />
                        {index === 2 && templates.length > 3 && (
                          <p className="col-span-3 text-left my-2 font-lato font-bold">
                            Custom Templates
                          </p>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <p>No templates available.</p>
              )}
            </div>
            {templateModal && (
              <TemplateModal
                templateModal={templateModal}
                data={selectedTemplate?.data}
                setTemplateModal={setTemplateModal}
                description={selectedTemplate?.template?.description}
                template={selectedTemplate?.template}
                title={selectedTemplate?.title}
                primaryColor={selectedTemplate?.primaryColor}
                secondaryColor={selectedTemplate?.secondaryColor}
                handleUpdateTemplate={handleUpDateTemplate}
                readOnly={!(user?.roleAccess?.Templates?.Create)}
                handleSchedule={handleSchedule}
              />
            )}
          </CustomScrollbar>
        </div>
        <ScheduleMeetingModal
          isOpen={okrSlice?.openCreateScheduleModal}
          onClose={handleClose}
          formData={formData}
          setformData={setformData}
          Schedule={`${selectedTemplate?.template?.id || ""} ${
            selectedTemplate?.title || ""
          }`}
        />
        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleConfirmDelete}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default TouchbaseTemplates;
