import React, { useState } from "react";
import { Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Sessions } from "../../utils/Constants";
import { ToastContainer, toast } from "react-toastify";
import { baseURL } from "../../utils/config";
import GoalForm from "./GoalForm";
import ObjectiveForm from "./ObjectiveForm";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import KeyResultForm from "./KeyResultForm";
import { GoGoal } from "react-icons/go";
import { Button } from "antd";
import { api } from "../global/api";
const ButtonGroup = ({ onEditClick }) => {
  return (
    <div className="flex justify-end mt-4 gap-x-2">
      <button
        onClick={onEditClick}
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
      >
        Edit
      </button>
    </div>
  );
};

const SessionDetails = ({ data, message, isEditing, setIsEditing }) => {
  const [titleError, setTitleError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [parentError, setParentError] = useState(false);
  const [createGoalLoading, setCreateGoalLoading] = useState(false);
  const [goalcreated, setGoalCreated] = useState(null);
  const [objectivecreated, setObjectiveCreated] = useState(false);
  const [keyresultcreated, setKeyResultCreated] = useState(false);
  const [targetError, setTargetError] = useState(false);
  const [keyResultError, setKeyResultError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [initialError, setInitialError] = useState(false);
  const [diffError, setDiffError] = useState(false);
  const [deadlineError, setDeadlineError] = useState(false);
  const userold = useSelector((state) => state.user);
  const currentUser = userold?.currentUser;

  const sessionObj = Sessions?.find(
    (item) => item?.value === parseInt(message?.session)
  );
  const owners = data?.owners?.map((item) => {
    return item?.id + " " + item?.type;
  });

  const [formData, setformData] = useState({
    session: sessionObj,
    title: message?.title,
    description: message?.description,
    owner: owners,
    // parent :
  });
  const onChangeTimePicker = (time, timeString) => {
    if (timeString) setDeadlineError(false);
    setformDataKey({ ...formDataKey, deadline: timeString });
  };

  function changeHandeler(event) {
    const { name, value } = event.target;
    if (name === "title") {
      //  setTitleError(false);

      if (value && value.length <= 70) {
        setTitleError(false);
      }
    }
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const [formDataObj, setformDataObj] = useState({
    session: sessionObj,
    title: message?.children[0]?.objective_title,
    owner: owners,
    parent: "",
    // members: "",
    // description: "",
  });
  const [okrData, setOkrData] = useState();

  function changeHandelerObj(event) {
    const { name, value } = event.target;
    if (name === "title") {
      //  setTitleError(false);

      if (value && value.length <= 70) {
        setTitleError(false);
      }
    }
    setformDataObj((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const [formDataKey, setformDataKey] = useState({
    session: sessionObj,
    title: message?.children[0]?.children[0]?.key_result_title,
    description: message?.children[0]?.children[0]?.key_result_description,
    unit: message?.children[0]?.children[0]?.unit,
    key_result_type: message?.children[0]?.children[0]?.key_result_type,
    initial_number: message?.children[0]?.children[0]?.initial_number,
    target_number: message?.children[0]?.children[0]?.target_number,
    owner: owners,
    deadline: message?.children[0]?.children[0]?.deadline,
    parent: "",
    // members: "",
    // description: "",
  });

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const saveData = async (event) => {
    let user_id = [];
    let team_id = [];
    let parent;
    let parent_type;
    if (!formData.title) {
      setTitleError(true);
      return;
    }
    if (!formData?.owner?.length) {
      setOwnerError(true);

      return;
    } else {
      formData?.owner?.forEach((item) => {
        let typeArr = item?.split(" ");
        if (typeArr[1] === "user") {
          user_id?.push(typeArr[0]);
        } else {
          team_id?.push(typeArr[0]);
        }
      });
    }
    if (formData?.parent_val) {
      const parentArr = formData?.parent_val?.split(" ");
      parent = parentArr[0];
      parent_type = parentArr[1];
    }

    try {
      setCreateGoalLoading(true);

      const currentEmployee = currentUser?.employees;
      let company_id;
      let login_user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[userold?.currentCompany]?.company_id;
        login_user_id = currentEmployee[userold?.currentCompany]?.user_id;
      }
      const session = formData?.session?.value ?? formData?.session;
      const result = await api.post("/goal/", {
        title: formData.title,
        user_id: user_id,
        team_id: team_id,
        description: formData.description,
        company_id: company_id,
        session: session,
        parent: parent,
        parent_type: parent_type,
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "goal_created",
        message: "Goal created succesfully!.",
        id: result?.data?.goal_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      setGoalCreated(result?.data?.goal_id);
      toast.success("Goal created succesfully!.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
      toast.error("Try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setCreateGoalLoading(false);
      setIsEditing(false);
    }
  };

  const saveDataObj = async (event) => {
    let user_id = [];
    let team_id = [];
    let parentGoal;
    if (!formDataObj.title) {
      return;
    }

    if (!formDataObj?.owner?.length) {
      setOwnerError(true);
      return;
    }
    if (!formDataObj?.session === null || !formData?.session) {
      setSessionError(true);
      return;
    }
    if (!formDataObj?.parent_val?.length) {
      setParentError(true);
      return;
    } else {
      formDataObj?.owner?.forEach((item) => {
        let typeArr = item?.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    }

    // if (formDataObj?.parent_val) {
    //   const parentValArr = formData?.parent_val?.split(" ");
    //   parentGoal = parentValArr[0];
    // } else {
    parentGoal = goalcreated;
    // }

    setCreateGoalLoading(true);
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      let login_user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[userold?.currentCompany]?.company_id;
        login_user_id = currentEmployee[userold?.currentCompany]?.user_id;
      }
      const session = formData?.session?.value ?? formData?.session;
      const result = await api.post("/okr/", {
        session: session,
        parent: true,
        title: formDataObj.title,
        user_id: user_id,
        team_id: team_id,
        company_id: company_id,
        goal_id: parentGoal,
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "okr_created",
        message: "Objective created succesfully!.",
        id: result?.data?.okr_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      setObjectiveCreated(result?.data?.okr_id);
      toast.success("Objective created succesfully!.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
      toast.error("Try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      // handleEdit();
      setIsEditing(false);
      setCreateGoalLoading(false);
    }
  };

  const saveDataKey = async (event) => {
    event.preventDefault();
    let okr_id = objectivecreated;
    let user_id = [];
    let team_id = [];

    if (formDataKey?.title === "") {
      setTitleError(true);
      return;
    }
    if (!formDataKey?.owner) {
      setOwnerError(true);
      return;
    }
    if (formDataKey?.deadline === "") {
      setDeadlineError(true);
      return;
    }
    if (
      formDataKey?.key_result_type === "" ||
      formDataKey?.key_result_type === ""
    ) {
      setKeyResultError(true);
      return;
    }
    if (formDataKey?.unit || formDataKey?.unit === "") {
      setUnitError(true);
      return;
    }
    if (formDataKey?.target_number === "") {
      setTargetError(true);
      return;
    }
    if (formDataKey?.initial_number === "") {
      setInitialError(true);
      return;
    }

    if (formDataKey?.owner?.length) {
      formDataKey?.owner?.forEach((item) => {
        let typeArr = item.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    } else {
      setOwnerError(true);
      return;
    }

    if (formDataKey?.deadline === "") {
      toast.warn("Deadline is required", {
        position: "center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDeadlineError(true);
      return;
    }
    if (diffError) {
      return;
    }

    setCreateGoalLoading(true);
    try {
      const result = await api.post("/key_results/", {
        title: formDataKey.title,
        key_result_type: formDataKey.key_result_type,
        unit: formDataKey.unit,
        target_number: formDataKey.target_number,
        initial_number: formDataKey.initial_number,
        description: formDataKey.description,
        deadline: formDataKey.deadline,
        okr_id: okr_id || objectivecreated,
        company_id: currentUser?.employees[userold?.currentCompany]?.company_id,
        user_ownwer_id:
          currentUser?.employees[userold?.currentCompany]?.user_id,
        user_id: user_id,
        team_id: team_id,
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "key_result_created",
        message: "Key Result created succesfully!.",
        id: result?.data?.key_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      setKeyResultCreated(true);
      toast.success("Key Result created succesfully!.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
      toast.error("Try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setCreateGoalLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <div className="w-full">
      {isEditing ? (
        <div>
          {!goalcreated && (
            <GoalForm
              formData={formData}
              setformData={setformData}
              changeHandler={changeHandeler}
              titleError={titleError}
              ownerError={ownerError}
              sessionError={sessionError}
              setTitleError={setTitleError}
              setOwnerError={setOwnerError}
              setSessionError={setSessionError}
              handleEditClick={handleEditClick}
              saveData={saveData}
            />
          )}
          {goalcreated && !objectivecreated && (
            <ObjectiveForm
              formData={formDataObj}
              setformData={setformDataObj}
              changeHandeler={changeHandelerObj}
              titleError={titleError}
              ownerError={ownerError}
              sessionError={sessionError}
              setTitleError={setTitleError}
              setOwnerError={setOwnerError}
              setSessionError={setSessionError}
              parentError={parentError}
              setParentError={setParentError}
              handleEditClick={handleEditClick}
              saveData={saveDataObj}
              okrData={okrData}
              setOkrData={setOkrData}
              goalcreated={goalcreated}
            />
          )}
          {goalcreated && objectivecreated && !keyresultcreated && (
            <KeyResultForm
              formData={formDataKey}
              saveData={saveDataKey}
              owners={owners}
              titleError={titleError}
              setTitleError={setTitleError}
              targetError={targetError}
              setTargetError={setTargetError}
              initialError={initialError}
              setInitialError={setInitialError}
              setKeyResultError={setKeyResultError}
              keyResultError={keyResultError}
              setUnitError={setUnitError}
              unitError={unitError}
              deadlineError={deadlineError}
              setDeadlineError={setDeadlineError}
              //  changeHandeler={changeHandler}
              setformData={setformDataKey}
              onChangeTimePicker={onChangeTimePicker}
              setDiffError={setDiffError}
              diffError={diffError}
              setOwnerError={setOwnerError}
              ownerError={ownerError}
            />
          )}
        </div>
      ) : (
        <div>
          <div>
            <div className="flex gap-x-2">
              <button
                className="rounded p-1"
                style={{
                  background:
                    "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                }}
              >
                <GoGoal
                  className="text-md"
                  style={{
                    color: "#B91C1C",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </button>
              <strong className="flex items-center">Goal</strong>
            </div>
            <p>
              {" "}
              <ArrowRightIcon /> Session: {sessionObj?.label}
            </p>
            <p>
              {" "}
              <ArrowRightIcon /> Title: {formData?.title}
            </p>
            <p>
              {" "}
              <ArrowRightIcon /> Description: {formData?.description}
            </p>
            {/* <p>Parent: {formData?.parent_val}</p> */}
            {!goalcreated && <ButtonGroup onEditClick={handleEditClick} />}

            {message?.children?.map((child, index) => (
              <div key={index} className="mt-2 ml-2">
                <div className="flex gap-x-2">
                  <button>
                    <img
                      src="/assets/images/objective.svg"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </button>
                  <strong className="flex items-center">Objective</strong>
                </div>
                <p>
                  {" "}
                  <ArrowRightIcon />
                  Title: {formDataObj?.title}
                </p>
                {/* <p>Parent: {formDataObj?.parent_val}</p> */}
                {goalcreated && !objectivecreated && (
                  <ButtonGroup onEditClick={handleEditClick} />
                )}
                {child?.map((grandchild, index) => (
                  <div key={index} className="mt-2 ml-4">
                    <p className="flex gap-x-2">
                      <button>
                        <img
                          src="/assets/images/keyresults.svg"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </button>
                      <strong className="flex items-center">Key Result</strong>
                    </p>
                    <p>
                      <ArrowRightIcon />
                      Title: {formDataKey?.title}
                    </p>
                    <p>
                      <ArrowRightIcon />
                      Description: {formDataKey?.description}
                    </p>
                    <p>
                      <ArrowRightIcon />
                      Unit: {formDataKey?.unit}
                    </p>
                    <p>
                      <ArrowRightIcon />
                      Type: {formDataKey?.key_result_type}
                    </p>
                    <p>
                      <ArrowRightIcon />
                      Initial Number: {formDataKey?.initial_number}
                    </p>
                    <p>
                      <ArrowRightIcon />
                      Target Number: {formDataKey?.target_number}
                    </p>
                    <p>
                      <ArrowRightIcon />
                      Deadline: {formDataKey?.deadline}
                    </p>
                  </div>
                ))}
                {goalcreated && objectivecreated && !keyresultcreated && (
                  <ButtonGroup onEditClick={handleEditClick} />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Box = ({
  title,
  Click,
  icon,
  bgColor,
  borderColor,
  hoverBgColor,
  hoverBorderColor,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const boxStyle = {
    backgroundColor: isHovered ? hoverBgColor : bgColor,
    border: `1px solid ${isHovered ? hoverBorderColor : borderColor}`,
    transition: "background-color 0.3s, border-color 0.3s",
    boxShadow: isHovered ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none",
  };

  return (
    <div
      onClick={Click}
      className="flex flex-col justify-between border-1 px-2 py-2 rounded-2xl cursor-pointer w-1/3 "
      style={boxStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h2
        className="text-base font-medium text-center"
        style={{
          color: "#242222",
        }}
      >
        {title}
      </h2>
      <img
        src={icon}
        alt={title}
        className="mx-auto"
        style={{
          width: "63px",
          height: "63px",
        }}
      />
    </div>
  );
};

const MessageList = ({
  messages,
  handleSummary,
  handleGoal,
  handleDoc,
  data,
  isEditing,
  setIsEditing,
  openModal,
  handleFileChange,
  handleUploadDoc,
  selectedFileName,
}) => {
  const [isHoveredButton, setIsHoveredButton] = useState(false);

  return (
    <div className="flex flex-col w-full space-y-4 py-2 h-full px-4">
      {messages?.length === 0 && !openModal && (
        <div className="flex w-full h-2/3 justify-evenly space-x-2 p-4">
          {data && (
            <Box
              title="Summarize"
              Click={handleSummary}
              bgColor="#FDF6E3"
              icon="/assets/images/ChatSummarize.png"
              borderColor="rgb(240, 182, 27, 0.17)"
              hoverBgColor="#FFF1CD"
              hoverBorderColor="rgb(240, 182, 27,0.39)"
            />
          )}
          <Box
            title="Create Goal"
            Click={handleGoal}
            bgColor="#FFF6F6" // Background color
            icon="/assets/images/chatgoal.png"
            borderColor="rgba(233, 68, 68, 0.17)"
            hoverBgColor="#FEEEEE"
            hoverBorderColor="rgb(233, 68, 68,0.3)"
          />
          <Box
            title="Create Goal from a Document"
            Click={handleDoc}
            bgColor="#ECF6FF" // Background color
            icon="/assets/images/chatgoaldoc.png"
            borderColor="rgb(0, 70, 146, 0.17)"
            hoverBgColor="#D2EAFF"
            hoverBorderColor="rgb(0, 70, 146,0.3)"
          />
        </div>
      )}
      {messages?.length === 0 && openModal && (
        <div className="flex items-center justify-center h-full w-full">
          <div className="flex flex-col w-full p-1 h-2/3">
            <div className="bg-gray-50 rounded flex flex-col items-center h-full justify-evenly p-1 border-2 border-dashed">
              <img src="/assets/images/DocUpload.png" className="w-14 h-14" />
              <p className="text-lg text-basic">Upload a document</p>
              <label className="text-md font-medium px-2 flex justify-between">
                <div className="relative border bg-button hover:bg-button-hover border-gray-300 rounded-md py-2 px-10 flex items-center justify-between w-full">
                  <span className="text-white mr-2">
                    {selectedFileName?.name
                      ? selectedFileName?.name
                      : "Select Document"}
                  </span>
                  <input
                    type="file"
                    className="absolute inset-0 opacity-0 z-10 w-full h-full cursor-pointer"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                  />
                </div>
              </label>
              <p className="p-1 text-xs text-center text-red-500">
                We accept (pdf and docx) files only. Maximum size of the file
                should be 1 mb.
              </p>
            </div>
            <div className=" mt-3 flex items-center justify-end col-span-6 gap-x-4">
              <Button onClick={handleDoc}>Cancel</Button>
              <Button
                disabled={!selectedFileName?.name}
                onClick={handleUploadDoc}
                onMouseEnter={() => setIsHoveredButton(true)}
                onMouseLeave={() => setIsHoveredButton(false)}
                style={{
                  backgroundColor: !selectedFileName?.name
                    ? "#1890ff"
                    : isHoveredButton
                    ? "#0050b3"
                    : "#1890ff",
                  color: "white",
                  borderColor: "#1890ff",
                  cursor: !selectedFileName?.name ? "not-allowed" : "pointer",
                }}
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      )}

      {messages?.length === 0 && !openModal ? (
        <div className="flex justify-center items-center h-full">
          <div className="flex flex-col space-y-4 items-center">
            <p className="text-2xl font-medium font-poppins">
              Welcome to Chatbot
            </p>
            <p className="text-base font-poppins">
              Your virtual assistant for seamless interactions
            </p>
          </div>
        </div>
      ) : (
        messages?.map((message, index) => (
          <>
            <div
              key={index}
              className="bg-blue-500 mt-2 text-white rounded-lg p-3  max-w-xs self-end"
            >
              {message?.query?.name || message?.query}
            </div>
            <div
              key={index + "_response"}
              className="bg-gray-200  flex justify-start text-gray-800 rounded-lg p-3 max-w-sm"
            >
              {message?.response ? (
                <Text as="p" whiteSpace="pre-line" className="w-full">
                  {message?.response?.session ? (
                    <SessionDetails
                      message={message?.response}
                      data={data}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  ) : (
                    <p className="w-full">{message?.response}</p>
                  )}
                </Text>
              ) : (
                <div className="flex gap-2">
                  <div className="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
                  <div className="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
                  <div className="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
                </div>
              )}
            </div>
          </>
        ))
      )}

      <ToastContainer />
    </div>
  );
};

export default MessageList;
