import React from "react";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ReportTable = ({ draftReports, handleDeleteReport }) => {
  const user = useSelector((state) => state?.user);
  return (
    <div className="w-full">
      <div className="grid grid-cols-4 w-full p-3 bg-header text-white">
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Name</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Owner(s)</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Type</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Actions</h3>
        </div>
      </div>
      {draftReports?.map((item, i) => (
        <div
          className={`grid grid-cols-4 w-full py-4  border border-b`}
          key={i}
        >
          <div className="flex items-center ml-5 justify-start  gap-x-2 hover:text-blue-600 col-span-1 w-full px-2">
            <Link className="pl-12" to={"/company/reports/" + item?.report_id}>
              {item?.name}
            </Link>
          </div>

          <div className="flex flex-col w-full items-center justify-center gap-x-2 col-span-1">
            <ul className="flex gap-x-2">
              <div
                className="w-5 h-5 text-sm border border-black rounded-full  flex items-center justify-center text-white "
                style={{
                  backgroundColor: item?.profile_image
                    ? "initial"
                    : getAvatarColor(item?.username),
                }}
              >
                {item?.profile_image ? (
                  <img
                    src={item?.profile_image}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                      display: "block",
                    }}
                  />
                ) : (
                  getInitials(item?.username)
                )}
              </div>
              <span>{item?.username}</span>
            </ul>
          </div>
          <div className="flex items-center justify-center gap-x-2 col-span-1">
            {item?.type === "key_results" ? "Key Result" : item?.type}
          </div>
          <div className="flex items-center justify-center gap-x-2 col-span-1">
            <button
              onClick={() => {
                if(user?.roleAccess?.Reports?.Delete){
                handleDeleteReport(item?.report_id);
                }
              }}
              className="bg-white px-2 transition-all rounded-lg"
              disabled={!user?.roleAccess?.Reports?.Delete}
            >
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  user?.roleAccess?.Reports?.Delete
                    ? "text-red-600 cursor-pointer"
                    : "text-red-200 cursor-not-allowed"
                }`}
              >
                <path
                  d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportTable;
