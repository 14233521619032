import React, { useEffect, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { Box, FormControl, FormLabel, Input, Spinner } from "@chakra-ui/react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { BiGlobe } from "react-icons/bi";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { api } from "../../components/global/api";
import { toast, ToastContainer } from "react-toastify";

const RoleAccess = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [teams, setTeams] = useState([]);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingCollaborate, setLoadingCollaborate] = useState(false);
  const currentEmployee = currentUser?.employees;
  const [users, setUsers] = useState(null);
  const [assignRole, setAssignRole] = useState([]);
  const [formData, setformData] = useState({
    email: null,
    assign_team: [],
    assign_role: null,
    inTeams: [],
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchEmployees = async () => {
    try {
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const response = await api.get(baseURL + "/employee_details/", {
        company_id: company_id,
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const getTeams = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const response = await api.get(baseURL + "/create-team/", {
        company_id: company_id,
      });
      setTeams(response?.data);
    } catch (error) {
      //  setError(error);
      console.log(error);
    }
  };

  const fetchRole = async () => {
    try {
      const response = await api.get(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      setAssignRole(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [emailError, setEmailError] = useState(false);

  function changeHandeler(event) {
    const { name, value } = event.target;
    console.log(value);
    setEmailError(!validateEmail(value));
    setformData((prevFormData) => ({
      ...prevFormData,
      email: value,
    }));
  }

  const handleInviteMember = async () => {
    setLoadingInvite(true);
    if (!formData?.email) {
      return;
    }

    if (emailError) {
      return;
    }

    if (formData?.assign_role === "") {
      return;
    }

    if (formData?.assign_team === "") {
      return;
    }

    // if (!roleAccess?.["Invite-Members"]?.Invite) {
    //   toast.error("You dont have access to this feature");
    //   return;
    // }

    try {
      const payload = {
        email: formData?.email,
        employee_role_id: formData?.assign_role,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        teams: formData?.assign_team,
      };
      const res = await api.post(baseURL + "/send-email/", payload);
      setLoadingInvite(false);
      setformData({
        email: "",
        assign_role: "",
        assign_team: null,
      });
      if (res?.status === 200) {
        toast.success("User has been Invited.");
      }
    } catch (error) {
      // console.log(error)
      setLoadingInvite(false);
      if (error?.response?.status == 404) {
        toast.error("Employee already exists in the company");
        setformData({
          email: "",
          assign_role: "",
          assign_team: null,
        });
      } else {
        setformData({
          email: "",
          assign_role: "",
          assign_team: null,
        });
        toast.error("Invite user failed. Please try again");
      }
    }
  };

  const handleSendCollaborate = async () => {
    setLoadingCollaborate(true);
    if (!formData?.user?.user_id) {
      return;
    }
    if (formData?.add_to_team === "") {
      return;
    }


    try {
      const payload = {
        employee_user_id: formData?.user?.user_id,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        team_id: formData?.add_to_team,
      };
      const res = await api.post(baseURL + "/team-colab/", payload);
      setLoadingCollaborate(false);
      setformData({
        user: "",
        add_to_team: "",
        inTeams: null,
      });
      if (res.status === 201) {
        toast.success("User has been Invited.");
      }
    } catch (error) {
      setLoadingCollaborate(false);
      if (error?.response?.status == 404) {
        setformData({
          user: "",
          add_to_team: "",
          inTeams: null,
        });
        toast.error("Invite user failed. Please try again");
      }
    }
  };

  const handleSelectChange = (selectedEmail) => {
    const selectedUser = users.find((user) => user.email === selectedEmail);
    setformData((prevFormData) => ({
      ...prevFormData,
      user: selectedUser || null,
      inTeams: selectedUser?.teams?.map((team) => team.team_id),
    }));
  };


  useEffect(() => {
    getTeams();
    fetchRole();
  }, []);

  return (
    <div className="overflow-y-auto">
      <CustomScrollbar>
        <div className="p-6 bg-white rounded-lg shadow-lg space-y-10 font-sans">
          <div className="space-y-2">
            <h1 className="text-3xl font-semibold">User Management</h1>
            <p className="text-gray-600 font-medium">
              Manage your team members and their account permissions here.
            </p>
          </div>

          <div className="border-t border-gray-300"></div>
          <div className="flex items-end justify-between space-x-5">
            <div className="space-y-1 w-1/3 ">
              <h1 className="text-2xl font-semibold">Invite Members</h1>
              <p className="text-gray-500 text-base">
                Get your projects up and running faster by inviting your team
                members.
              </p>
            </div>

            <input
              type="email"
              placeholder="john@example.com"
              value={formData?.email}
              onChange={(e) => {
                setEmailError(false);
                changeHandeler(e);
              }}
              className={`flex-1 bg-transparent text-gray-700 h-12 ${
                emailError ? "border-red-500 border-2" : ""
              }`}
            />

            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Team</p>
                </FormLabel>
                <Select
                  name="members"
                  id="members"
                  onChange={(value) => {
                    setformData({ ...formData, assign_team: value });
                  }}
                  value={formData?.assign_team}
                  required
                  mode="multiple"
                  className="h-12 w-full"
                  placeholder="Select Member"
                  suffixIcon={null}
                >
                  {teams?.map((team, index) => {
                    return (
                      <Select.Option
                        value={team?.team_id || team?.tema_id}
                        key={index}
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="p-1 bg-gray-600 rounded-full flex-shrink-0">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                          <span className="whitespace-nowrap">
                            {team?.team_name}
                          </span>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box width="10rem">
              <FormControl id="role">
                <FormLabel>
                  <p className="text-sm text-gray-500">Role</p>
                </FormLabel>
                <Select
                  className="h-12 w-full"
                  placeholder=""
                  name="assign_role"
                  id="assign_role"
                  onChange={(e) => {
                    setformData({ ...formData, assign_role: e });
                  }}
                  value={formData?.assign_role}
                >
                  {assignRole?.map((assign_role, index) => {
                    return (
                      <Select.Option
                        value={assign_role?.role_id}
                        key={index}
                        disabled={assign_role?.role_name === "Admin"}
                      >
                        <span className="capitalize">
                          {assign_role?.role_name}
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="flex justify-end">
            <button
              className={`bg-[#0081F9] text-white rounded-lg p-2 px-6 flex items-center space-x-2 ${
                !(
                  formData?.assign_role &&
                  formData?.email &&
                  formData?.assign_team
                )
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleInviteMember}
              disabled={
                !(
                  formData?.assign_role &&
                  formData?.email &&
                  formData?.assign_team
                )
              }
            >
              <EmailOutlinedIcon />
              <p className="font-semibold">Send Invite</p>
              {loadingInvite && (
                <Spinner size="sm" color="white" className="ml-2" />
              )}
            </button>
          </div>

          <div className="border-t border-gray-300"></div>
          <div className="flex items-end justify-between space-x-5">
            <div className="space-y-1 w-1/3 ">
              <h1 className="text-2xl font-semibold">Team Collaboration</h1>
              <p className="text-gray-500 text-base">
                Accelerate your projects by bringing your team on board to
                collaborate.
              </p>
            </div>
            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Select Email</p>
                </FormLabel>
                <Select
                  placeholder="Select a user"
                  onChange={handleSelectChange}
                  className="h-12 w-full"
                  value={formData.user?.email || ""}
                >
                  {users?.map((user) => (
                    <Select.Option key={user?.employee_id} value={user?.email}>
                      {user?.email}
                    </Select.Option>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Team</p>
                </FormLabel>
                <Select
                  className="h-12 w-full no-arrow cursor-not-allowed"
                  name="team"
                  id="team"
                  mode="multiple"
                  suffixIcon={null}
                  filterOption={false}
                  value={formData?.inTeams}
                  open={false}
                  disabled
                  tagRender={(props) => {
                    const { label, value } = props;
                    return (
                      <div className="flex items-center bg-white p-1  ml-2">
                        <span className="p-1 bg-gray-600 rounded-full">
                          <BiGlobe className="text-gray-300 text-xs" />
                        </span>
                        <span>{label}</span>
                      </div>
                    );
                  }}
                >
                  {formData?.user?.teams?.map((team) => (
                    <Select.Option value={team?.team_id} key={team?.team_id}>
                      <span className="capitalize">{team?.team_name}</span>
                    </Select.Option>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Collaborate With</p>
                </FormLabel>
                <Select
                  name="members"
                  id="members"
                  onChange={(value) => {
                    setformData({ ...formData, add_to_team: value });
                  }}
                  value={formData?.add_to_team}
                  required
                  className="h-12 w-full"
                  placeholder="Select Member"
                >
                  {teams?.map((team, index) => {
                    const isDisabled = formData?.inTeams?.includes(
                      team?.team_id || team?.tema_id
                    );

                    return (
                      <Select.Option
                        value={team?.team_id || team?.tema_id}
                        key={index}
                        disabled={isDisabled}
                      >
                        <div className="flex items-center gap-2">
                          <span className="p-1 bg-gray-600 rounded-full">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                          <span>{team?.team_name}</span>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="flex justify-end">
            <button
              className={`bg-[#0081F9] text-white rounded-lg p-2 px-6 flex items-center space-x-2 ${
                !(formData?.user?.employee_id && formData?.add_to_team)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={!(formData?.user?.employee_id && formData?.add_to_team)}
              onClick={handleSendCollaborate}
            >
              <EmailOutlinedIcon />
              <p className="font-semibold">Send Invite</p>
              {loadingCollaborate && (
                <Spinner size="sm" color="white" className="ml-2" />
              )}
            </button>
          </div>

          <ToastContainer />
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default RoleAccess;
