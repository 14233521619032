import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Tooltip } from "antd";
import {
  checkTeamPriveledge,
  getAvatarColor,
  getInitials,
} from "../../../utils/helpers";
import { MdGroups } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

export const TeamTables = ({
  data,
  fetchData,
  teamCount,
  setOpenEditModel,
  handleOpenEditModel,
  handleDeleteTeam,
}) => {
  const user = useSelector((state) => state?.user);
  const currentUser = user?.currentUser;

  return (
    <>
      <div className="h-full mx-auto p-3">
        <table className="min-w-full h-full">
          <thead className="border-b text-left bg-header text-white">
            <tr style={{ textAlign: "center" }}>
              <th className="py-2 text-left pl-10">Team Name</th>
              <th className="py-2">Team</th>
              <th className="py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="transition-all rounded hover:cursor-pointer py-4 border border-b text-center m-4  "
                >
                  <td className="py-2 text-center flex items-center h-full ">
                    <div
                      className="flex py-2 gap-x-2  items-center justify-between ml-5"
                      onClick={() => {
                        if (user?.roleAccess?.Teams?.Update) {
                          handleOpenEditModel({ ...item });
                        }
                      }}
                    >
                      <span
                        className="p-0.5 rounded-full"
                        style={{ backgroundColor: "#063F5D" }}
                      >
                        <div
                          style={{
                            border: "1px solid #fff",
                            borderRadius: "100vh",
                            padding: "3px",
                          }}
                        >
                          <MdGroups className="text-white" size={15} />
                        </div>
                      </span>
                      <p style={{ color: "#BB1D72" }}>{item.team_name}</p>
                    </div>
                  </td>
                  <td className="py-1 ">
                    <div className="flex gap-x-2 justify-center ">
                      <Avatar.Group>
                        {item?.employees?.map((employee, index) => (
                          <Tooltip key={index} title={employee?.username}>
                            <div key={index}>
                              <div
                                className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white"
                                style={{
                                  backgroundColor: employee?.profile_image
                                    ? "initial"
                                    : getAvatarColor(employee?.username),
                                }}
                              >
                                {employee?.profile_image ? (
                                  <img
                                    src={employee?.profile_image}
                                    alt="Profile"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      display: "block",
                                    }}
                                  />
                                ) : (
                                  getInitials(employee?.username)
                                )}
                              </div>
                            </div>
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    </div>
                  </td>
                  <td className="py-1">
                    <div className="flex gap-x-2 justify-center items-center">
                      <Tooltip
                        title={
                          !user?.roleAccess?.Teams?.Delete
                            ? "You cannot delete this team"
                            : ""
                        }
                      >
                        <button
                          onClick={() => {
                            handleDeleteTeam(item?.team_id);
                          }}
                          disabled={!user?.roleAccess?.Teams?.Delete}
                          className="bg-white transition-all rounded-lg"
                        >
                          <svg
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`${
                              user?.roleAccess?.Teams?.Delete
                                ? "text-red-600 cursor-pointer"
                                : "text-red-200 cursor-not-allowed"
                            }`}
                          >
                            <path
                              d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </Tooltip>

                      <Tooltip
                        title={
                          !user?.roleAccess?.Teams?.Update
                            ? "You cannot edit this team"
                            : ""
                        }
                      >
                        <button
                          onClick={() => {
                            handleOpenEditModel({ ...item });
                          }}
                          disabled={!user?.roleAccess?.Teams?.Update}
                          className={`bg-white transition-all rounded-lg text-lg ${
                            user?.roleAccess?.Teams?.Update
                              ? "cursor-pointer"
                              : "text-black/50 cursor-not-allowed"
                          }`}
                        >
                          <FaRegEdit />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
