import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCloseCreateOkrModal,
  handleOpenCreateOkrModal,
  handleUpdateSession,
} from "../../stores/slices/okrSlice";
import TableView from "../../components/Okrs/Goals/TableView";
import { Button, Modal, Select, Tooltip, TreeSelect } from "antd";
import { baseURL } from "../../utils/config";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import { handleUpdatePlan } from "../../stores/slices/userAuthSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GoGoal } from "react-icons/go";
import GoalDrawerComponent from "../../components/Okrs/Goals/GoalDrawerComponent";
import DrawerContainer from "../../components/Okrs/Drawer";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { Sessions } from "../../utils/Constants";
import { BiGlobe } from "react-icons/bi";
import { api } from "../../components/global/api";

const AllGoals = () => {
  const location = useLocation();
  const okrSlice = useSelector((state) => state.okr);
  const [goalData, setGoalData] = useState();
  const [isModalOpen, setisModalOpen] = useState(false);
  const hasGoalsParam = new URLSearchParams(window.location.search).has(
    "goals"
  );
  const user = useSelector((state) => state?.user);
  const plan = user?.currentPlan;
  const [okrData, setOkrData] = useState();
  const [drawerData, setDrawerData] = useState();
  const [titleError, settitleError] = useState(false);
  const [keyResultDrawerData, setKeyResultDrawerData] = useState();
  const [parentError, setParentError] = useState(false);
  const [loadingObj, setLoadingObj] = useState(false);
  const [parentDropdownItems, setParentDropDownItems] = useState();
  const navigate = useNavigate();
  const { goalid } = useParams();

  if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
    navigate("/company/billing");
  }

  useEffect(() => {
    setformData({
      parent_val: okrData?.goal_id ? `${okrData.goal_id} goal` : null,
      title: "",
      description: "",
    });
    fetchParent();
  }, [okrData]);

  const fetchParent = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await api.get(baseURL + "/assignparents/", {
        company_id: currentEmployee?.company_id,
        session: okrSlice?.session,
      });
      const tempParentTree = response.data?.map((goal) => {
        return {
          label: (
            <p className="flex gap-x-2 items-center">
              <button
                className="rounded p-1"
                style={{
                  background:
                    "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                }}
              >
                <GoGoal
                  className="text-md"
                  style={{
                    color: "#B91C1C",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </button>
              {goal?.title}
            </p>
          ),
          value: goal?.goal_id + " " + "goal",
          type: "goal",
        };
      });
      setParentDropDownItems(tempParentTree);
    } catch (error) {}
  };

  function changeHandeler(event) {
    const { name, value } = event.target;
    if (name === "title" && value) {
      settitleError(false);
    }
    if (name === "session") {
      setSessionError(false);
    }
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const showModal = () => {
    setisModalOpen(true);
    dispatch(handleOpenCreateOkrModal(true));
  };

  const handleOk = () => {
    setisModalOpen(false);
  };

  const handleCancel = () => {
    setisModalOpen(false);
    setformData((prevFormData) => ({
      ...prevFormData,
      title: "",
      owner: [],
    }));
    dispatch(handleCloseCreateOkrModal());
  };

  const saveData = async (event) => {
    event.preventDefault();

    let user_id = [];
    let team_id = [];
    let parentGoal;
    if (!formData.title) {
      settitleError(true);
      return;
    }

    if (!formData?.owner?.length) {
      setOwnerError(true);
      return;
    }
    if (formData?.session === null || formData?.session === undefined) {
      setSessionError(true);
      return;
    }
    if (!formData?.parent_val?.length) {
      setParentError(true);
      return;
    } else {
      formData?.owner?.forEach((item) => {
        let typeArr = item.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    }

    if (formData?.parent_val) {
      const parentValArr = formData?.parent_val.split(" ");
      parentGoal = parentValArr[0];
    } else {
      parentGoal = okrData?.goal_id;
    }

    setLoadingObj(true);
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      let login_user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
        login_user_id = currentEmployee[user?.currentCompany]?.user_id;
      }

      const result = await api.post(baseURL + "/okr/", {
        session: formData?.session,
        parent: true,
        title: formData.title,
        user_id: user_id,
        team_id: team_id,
        company_id: company_id,
        goal_id: parentGoal,
      });
      getOkrData();
      getGoals();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "okr_created",
        message: "Objective created succesfully!.",
        id: result?.data?.okr_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      setformData((prevFormData) => ({
        ...prevFormData,
        title: "",
        owner: [],
      }));
      getGoals();
    } catch (err) {
      console.log(err);
      getOkrData();
      handleCancel();
      setformData((prevFormData) => ({
        ...prevFormData,
        title: "",
        owner: [],
      }));
    } finally {
      getOkrData();
      handleCancel();
      getGoals();
      setLoadingObj(false);
    }
  };

  const [owner, setOwner] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (okrSlice?.openOkrDrawer) {
      getOkrData(okrSlice?.openOkrDrawer);
    }
  }, [okrSlice?.openOkrDrawer]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        let user_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await api.get(baseURL + "/assignowners/", {
          company_id: company_id,
        });
        setOwner(response.data);
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };

    if (okrSlice?.openCreateOkrModal) fetchData();
  }, [okrSlice?.openCreateOkrModal, drawerData]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleToggleDrawer = (data) => {
    setOpenDrawer(!openDrawer);
  };
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      // setTimeout(() => {
      // setLoader(false);
      setState({ run: true, stepIndex: 1 });
      // }, 1200);
    }
  });

  const [ownerError, setOwnerError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);

  const dispatch = useDispatch();
  const [owners, setOwners] = useState();
  const [goals, setGoals] = useState();
  const currentCompany = user?.currentUser?.employees[user?.currentCompany];
  const [goalsLoading, setGoalsLoading] = useState(false);
  const [filterSession, setFilteredSession] = useState(0);
  const [formDataFilter, setformDatafilter] = useState({
    session: null,
    goal_name: "",
    goal_owner: [],
    okr_name: "",
    okr_owner: [],
    title: "",
    progress: {
      comparison: "equal_to",
      value: null,
    },
    lastUpdated: {
      comparison: "before",
      value1: null,
      value2: null,
    },
  });
  const [formData, setformData] = useState({
    session: okrSlice?.session || null,
    title: null,
    owner: [],
    description: null,
    parent_val: null,
  });
  useEffect(() => {
    setformData({
      session: okrSlice?.session || null,
      title: null,
      owner: [],
      description: null,
    });
  }, [okrSlice?.session]);

  const getOkrData = async (okr_id) => {
    try {
      const res = await api.get(baseURL + "/okr/", {
        okr_id: okr_id,
        company_id: currentCompany?.company_id,
      });
      setDrawerData(res?.data?.children[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getGoals = async () => {
    let user_ids1 = [];
    let team_ids1 = [];
    formDataFilter?.goal_owner?.forEach((item) => {
      let typeArr = item.split(" ");
      if (typeArr[1] === "user") {
        user_ids1.push(typeArr[0]);
      } else {
        team_ids1.push(typeArr[0]);
      }
    });
    let user_ids2 = [];
    let team_ids2 = [];
    formDataFilter?.okr_owner?.forEach((item) => {
      let typeArr = item.split(" ");
      if (typeArr[1] === "user") {
        user_ids2.push(typeArr[0]);
      } else {
        team_ids2.push(typeArr[0]);
      }
    });
    try {
      setGoalsLoading(true);
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      let lastUpdatedValue = "";
      if (formDataFilter?.lastUpdated?.comparison === "never") {
        lastUpdatedValue = true;
      } else if (formDataFilter?.lastUpdated?.comparison === "between") {
        const first = formDataFilter?.lastUpdated?.value1?.$d?.toISOString();
        const second = formDataFilter?.lastUpdated?.value2?.$d?.toISOString();
        lastUpdatedValue = `${first},${second}`;
      } else {
        lastUpdatedValue =
          formDataFilter?.lastUpdated?.value1?.$d?.toISOString();
      }
      const params = {
        goal_name: formDataFilter?.goal_name,
        okr_name: formDataFilter?.okr_name,
        okr_owner: { user_ids: user_ids2, team_ids: team_ids2 },
        goal_owner: { user_ids: user_ids1, team_ids: team_ids1 },
        progress: {
          [formDataFilter?.progress?.comparison]:
            formDataFilter?.progress?.value,
        },
        last_updated: {
          [formDataFilter?.lastUpdated?.comparison]: lastUpdatedValue,
        },
      };
      const endpoint = hasGoalsParam ? "/shared-okr/" : "/goal/";
      const result = await api.get(baseURL + endpoint, {
        company_id: company_id,
        session: filterSession,
        fields: JSON.stringify(params),
        user_id: currentCompany?.user_id,
      });
      // const result = null;
      setGoals(result.data);
      setGoalsLoading(false);
      fetchParent();
    } catch (error) {
      if (error?.response?.status === 404) {
        setGoals([]);
      }
      console.log(error);
    }
  };
  useEffect(() => {
    getGoals();
  }, [formDataFilter, filterSession]);

  const fetchData = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const response = await api.get(baseURL + "/assignowners/", {
        company_id: company_id,
      });
      setOwners(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
    navigate("/company/billing");
  }

  function changeHandeler(event) {
    const { name, value } = event.target;
    if (name === "title") {
      //  setTitleError(false);

      if (value && value.length <= 70) {
        settitleError(false);
      }
    }
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await api.get(baseURL + "/subscription/", {
        company_id: currentCompany?.company_id,
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col w-full h-screen overflow-y-hidden">
      <TableView
        goals={goals}
        getOkrData={getOkrData}
        goalsLoading={goalsLoading}
        owners={owners}
        formData={formDataFilter}
        setformData={setformDatafilter}
        filterSession={filterSession}
        setFilteredSession={setFilteredSession}
        hasGoalsParam={hasGoalsParam}
      />
      <GoalDrawerComponent
        drawerData={goals}
        okrData={okrData}
        getOkrs={getGoals}
      />
      <DrawerContainer
        openDrawer={openDrawer}
        handleToggleDrawer={handleToggleDrawer}
        drawerData={drawerData || okrSlice?.currentOkrData}
        keyResultDrawerData={keyResultDrawerData}
        setKeyResultDrawerData={setKeyResultDrawerData}
        getOkrData={getOkrData}
        getOkrs={getGoals}
      />
      <Modal
        className="text-2x"
        title=""
        open={okrSlice?.openCreateOkrModal}
        // onOk={saveData}
        okText="Create Objective"
        onCancel={() => dispatch(handleCloseCreateOkrModal())}
        footer={null}
        width={550}
      >
        <div className="">
          <h1 className="p-2 m-2 text-3xl font-bold text-black">
            Create Objective
          </h1>
          <p className="p-2 m-2 text-gray-600">
            All required fields are marked with an asterisk (*).
          </p>

          <div className="bg-green">
            <form className="flex flex-col" onSubmit={saveData}>
              <div className="flex p-1 m-2 gap-7">
                <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                  Session*
                </label>
                <br />

                <div className="w-full">
                  <div className="flex gap-x-2 items-center">
                    <Select
                      name="session"
                      id="session"
                      onChange={(value) => {
                        setSessionError(false);
                        setformData({ ...formData, session: value });
                      }}
                      placeholder="Enter Session"
                      value={formData?.session}
                      required
                      // mode="multiple"
                      className="w-full"
                    >
                      {Sessions?.map((session, index) => {
                        return (
                          <Select.Option value={session.value} key={index}>
                            {session.label}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      title="Objective can be assigned to multiple people and/or teams"
                    >
                      <AiOutlineQuestionCircle className="text-gray-500 text-xl" />
                    </Tooltip>
                  </div>
                  {sessionError && (
                    <p className="text-red-500 mt-1">Session is required</p>
                  )}
                </div>
              </div>

              <div className="flex p-1 m-2 gap-7">
                <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                  Title*
                </label>
                <br />
                <div className="w-full">
                  <div className="flex gap-x-2 items-center">
                    <input
                      className="w-full rounded-lg border border-gray-300 px-2 py-1 text-sm"
                      type="text"
                      name="title"
                      id="title"
                      // onChange={changeHandeler}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          value !== " " &&
                          /[a-zA-Z0-9]/.test(value) &&
                          !/^[\s]+$/.test(value)
                        ) {
                          changeHandeler(e);
                        } else if (value === "") {
                          changeHandeler(e);
                        }
                      }}
                      value={formData.title}
                      placeholder="Enter a Value"
                      maxLength={70}
                      required
                    />

                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      title="Consider a title no longer than 70 characters. it will make OKR clear, punchy and easy to remember."
                    >
                      <AiOutlineQuestionCircle className="text-gray-500 text-xl" />
                    </Tooltip>
                  </div>
                  {titleError && (
                    <p className="text-red-500 mt-1">Title is required</p>
                  )}
                </div>
              </div>

              <div className="flex p-1 m-2 gap-7">
                <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                  Owner*
                </label>
                <br />

                <div className="w-full">
                  <div className="flex gap-x-2 items-center">
                    <Select
                      name="=owner"
                      id="owner"
                      onChange={(val) => {
                        setformData({ ...formData, owner: val });
                        setOwnerError(false);
                      }}
                      value={formData.owner}
                      mode="multiple"
                      required
                      className="w-full  "
                      placeholder="Select Owner"
                    >
                      {owner?.map((owner, index) => {
                        return (
                          <Select.Option
                            value={owner?.id + " " + owner?.type}
                            key={index}
                          >
                            <div className="flex items-center gap-x-2">
                              {owner?.type === "user" ? (
                                <div
                                  className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                                  style={{
                                    backgroundColor: owner?.profile_image
                                      ? "initial"
                                      : getAvatarColor(owner?.username),
                                  }}
                                  size={18}
                                >
                                  {owner?.profile_image ? (
                                    <img
                                      src={owner?.profile_image}
                                      alt="Profile"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                        display: "block",
                                      }}
                                    />
                                  ) : (
                                    getInitials(owner.name)
                                  )}
                                </div>
                              ) : (
                                <span className="p-1 bg-gray-600 rounded-full">
                                  <BiGlobe className="text-gray-300 text-xs" />
                                </span>
                              )}
                              <span>{owner.name}</span>
                            </div>
                          </Select.Option>
                        );
                      })}

                      {/* calling parent form  */}
                    </Select>

                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      className="text-gray-500"
                      title="You can change the owner of the Objective"
                    >
                      <AiOutlineQuestionCircle className="text-gray-500 text-xl" />
                    </Tooltip>
                  </div>
                  {ownerError && (
                    <p className="text-red-500 mt-1">Owner is required</p>
                  )}
                </div>
              </div>
              <div className="flex p-1 m-2 gap-7">
                <label
                  htmlFor="parent-input"
                  className="w-1/4 pt-2  text-xs text-slate-700"
                >
                  Parent*
                </label>

                <br />

                <div className="w-full">
                  <div className="flex items-center gap-x-2">
                    <TreeSelect
                      showSearch
                      className="w-full"
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      placeholder="Assign Parent"
                      // allowClear
                      // defaultValue={okrData?.goal_id + " " + "goal"}
                      required
                      value={formData?.parent_val}
                      // treeDefaultExpandAll
                      onChange={(newVal) => {
                        setformData({ ...formData, parent_val: newVal });
                        setParentError(false);
                      }}
                      treeData={parentDropdownItems}
                    />
                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      title="Assign a Parent goal for the Objective."
                    >
                      <AiOutlineQuestionCircle className="text-xl text-gray-500 cursor-pointer" />
                    </Tooltip>
                  </div>
                  {parentError && (
                    <p className="text-red-500 mt-1">Parent is required</p>
                  )}
                </div>
              </div>

              <div className="self-end p-2 flex gap-x-4">
                <Button
                  type="default"
                  className="py"
                  htmlType="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-button  text-white"
                  htmlType="submit"
                  loading={loadingObj}
                >
                  Create Objective
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AllGoals;
