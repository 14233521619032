import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInitials } from "../utils/helpers";
import DashboardHeader from "../components/global/DashboardHeader";
import EmployeeHeader from "../components/global/EmployeeHeader";
import CustomScrollbar from "../components/global/CustomScrollBar";
import { IoIosArrowRoundForward } from "react-icons/io";
import { updateTitleData } from "../stores/slices/userAuthSlice";

const DashboardMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const assign_role = ["user", "admin"];

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const plan = user?.currentPlan;
  const currentEmployee = currentUser?.employees[user?.currentCompany];

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "Dashboard",
        ClickLink: "Company / Dashboard",
      })
    );
  }, []);

  const CompanyGoalsCard = ({
    title,
    description,
    Icon,
    BackgroundImage,
    Navigate,
  }) => {
    return (
      <div
        className="max-w-md mx-auto font-lato bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl h-auto flex flex-col justify-between"
        style={{ maxWidth: "700px", minheight: "350px" }}
      >
        <div className=" p-6">
          <div className="flex justify-evenly space-x-5">
            <img className="h-20 w-20" src={Icon} alt={title} />
            <div className="flex flex-col space-between">
              <div className="text-md text-[#3D3B36] font-semibold font-lato">
                {title}
              </div>
              <p className=" text-[#979795] font-medium font-lato">
                {description}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center p-2">
            <img className="w-3/5 h-1/4" src={BackgroundImage} alt="data" />
          </div>
        </div>
        <div className="p-3">
          <div className="flex justify-between h-full bg-[#F9FAFC] rounded-md">
            <button className=" ml-2 text-[#3D3B3B]  font-semibold">
              See Details
            </button>
            <button
              onClick={() => navigate(Navigate)}
              className="w-1/6  flex justify-center  rounded-tl-xl items-center group transition-all duration-300"
            >
              <IoIosArrowRoundForward
                className="transform transition-transform duration-300 group-hover:translate-x-2"
                size={30}
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-screen p-4 font-lato ">
      <EmployeeHeader
        companyName={currentEmployee?.company_name}
        initials={getInitials(currentEmployee?.company_name)}
      />
      <div
        className="  flex flex-col px-6 py-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "80vh",
        }}
      >
        <div className="overflow-y-auto">
          <CustomScrollbar>
            <div className="grid grid-cols-2 mt-4 gap-4 font-lato">
              <CompanyGoalsCard
                title="Company Goals"
                description="We are committed to excellence, customer satisfaction, and
                continuous improvement in all aspects of our business."
                Icon="/assets/images/companygoals.png"
                BackgroundImage="/assets/images/companygraph.png"
                Navigate="/company/sessions"
              />
              <CompanyGoalsCard
                title="Teams"
                description="We are committed to excellence, customer satisfaction, and
                continuous improvement in all aspects of our business."
                Icon="/assets/images/companyteams.png"
                BackgroundImage="/assets/images/TeamsIcon.png"
                Navigate="/company/people"
              />
              <CompanyGoalsCard
                title="Insights"
                description="We are committed to excellence, customer satisfaction, and
                continuous improvement in all aspects of our business."
                Icon="/assets/images/companyinsights.png"
                BackgroundImage="/assets/images/companyinsightsbackground.png"
                Navigate="/company/insight"
              />
              <CompanyGoalsCard
                title="Reports"
                description="We are committed to excellence, customer satisfaction, and
                continuous improvement in all aspects of our business."
                Icon="/assets/images/companyreports.png"
                BackgroundImage="/assets/images/companyreportsbackground.png"
                Navigate="/company/insight"
              />
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
