import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function Nav() {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
          alignItems={"center"}
        >
          <Box ml={8}>
            {/* <Image src= {Imongu2Image} /> */}
            <Image
              decoding="asynchronous"
              src={"/assets/images/imongu2.svg"}
              onClick={() => {
                navigate("/home");
              }}
              alt="Logo"
              height={"50px"}
              width={"100px"}
            />
          </Box>
          <Flex
            display={{ base: "none", md: "flex" }}
            mx={["4", "8", "12", "16"]}
          >
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          color={"#011824"}
          spacing={6}
        >
          <Button
            as={"a"}
            fontSize={"sm"}
            fontWeight={400}
            variant={"link"}
            href={"/login"}
            color={"#011824"} // Set the color to #011824
            _hover={{ textDecoration: "none" }} // Remove underline on hover
          >
            Log in
          </Button>
          <Button
            as={"a"}
            // display={{ md: 'inline-flex' }}
            fontSize={"sm"}
            fontWeight={600}
            textAlign={"center"}
            // variant={'link'}
            color={"#FFFFFF"}
            bg={"#F27E0D"}
            href={"/register"}
            _hover={{
              bg: "#F27E0D",
            }}
          >
            Get Started
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box
          key={navItem.label}
          fontSize={"sm"}
          p={2}
          cursor={"pointer"}
          fontWeight={500}
          color={linkColor}
          _hover={{ textDecoration: "none", color: linkHoverColor }}
        >
          {navItem.children ? (
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Flex align="center">
                  <Text>{navItem.label}</Text>
                  <ChevronDownIcon boxSize={4} ml={1} mt={1} />
                </Flex>
              </PopoverTrigger>
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"xs"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            </Popover>
          ) : (
            <>
              <ScrollLink
                // as={Box}
                to={navItem.to || "#"}
                onClick={navItem?.onClick}
                spy={navItem.spy}
                smooth={navItem.smooth}
                offset={navItem.offset}
                duration={navItem.duration}
              >
                {!(navItem?.label === "Pricing") && navItem.label}
              </ScrollLink>

              <ScrollLink
                as={Box}
                to={navItem.to || "#"}
                onClick={() => {
                  if (
                    window.location.pathname === "/resources" &&
                    navItem.onClick
                  ) {
                    navItem.onClick();
                    const scrollToOptions = {
                      spy: navItem.spy,
                      smooth: navItem.smooth,
                      offset: navItem.offset,
                      duration: navItem.duration,
                    };
                    scroll.scrollTo(navItem.to || "#", scrollToOptions);
                  }
                }}
                spy={navItem.spy}
                smooth={navItem.smooth}
                offset={navItem.offset}
                duration={navItem.duration}
              >
                {navItem?.label === "Pricing" && navItem.label}
              </ScrollLink>
            </>
          )}
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Box
      as="a"
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        // py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

// interface NavItem {
//   label: string
//   subLabel?: string
//   children?: Array<NavItem>
//   href?: string
// }

const NAV_ITEMS = [
  {
    label: "Home",
    to: "/",
    onClick: () => {
      window.location.href = "/";
    },
    // spy: true,
    // smooth: true,
    // offset: -50,
    // duration: 500,
  },
  // {
  //   label: 'Benefits',
  // },
  {
    label: "Pricing",
    to: "pricing",
    spy: true,
    smooth: true,
    offset: -50,
    duration: 500,
  },
  {
    label: "Features",
    to: "features",
    spy: true,
    smooth: true,
    offset: -50,
    duration: 500,
  },
  {
    label: "iMongu Academy",
    children: [
      {
        label: "Executive Leadership",
        // href: '/academy/course1',
      },
      {
        label: "IT Admin",
        // href: '/academy/course2',
      },
      {
        label: "Coaching",
        // href: '/academy/course2',
      },
      // Add more courses as needed
    ],
  },
  {
    label: "Resources",
    onClick: () => {
      window.location.href = "/resources";
    },
  },
];
