import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import { Employees } from "../../pages/People/Employees";
import { Teams } from "../../pages/People/Teams";
import { useSearchParams } from "react-router-dom";

const People = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "Employees";
  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    setSearchParams({ tab: selectedTab });

    dispatch(
      updateTitleData({
        title: "People",
        ClickLink: `People / ${selectedTab}`,
      })
    );
  }, [dispatch, selectedTab, setSearchParams]);

  const tabs = ["Employees", "Teams"];

  return (
    <div className="relative h-screen p-4">
      <div className="flex items-center space-x-16 px-5">
        {tabs?.map((tab) => (
          <h1
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`relative cursor-pointer ${
              selectedTab === tab ? "text-orange-500 font-sans" : ""
            }`}
          >
            {tab}
            {selectedTab === tab && (
              <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
            )}
          </h1>
        ))}
      </div>
      <div className="border-b-2 border-gray-300 mb-4 mt-2"></div>
      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        {selectedTab === "Employees" && <Employees />}
        {selectedTab === "Teams" && <Teams />}
      </div>
    </div>
  );
};

export default People;
