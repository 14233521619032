import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
import DashboardLayout from "./pages/Users/DashboardLayout";
import { ViewOkr } from "./pages/Goals/Okr/ViewOkr";
import { googleClientId } from "./utils/config";
import { ChakraProvider } from "@chakra-ui/react";
import Home from "./pages/Home";
import AllGoals from "./pages/Goals/AllGoals";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ReportDraftPage from "./pages/Report/ReportDraftPage";
import BilingPage from "./pages/Billing/BilingPage";
import EmailVerification from "./pages/EmailVerification";
import DashboardMain from "./pages/DashboardMain";
import React, { Suspense } from "react";
import DashboardOwner from "./pages/Dashboardowner";
import Touchbase from "./pages/Touchbase/touchbase";
import TouchbaseSchedule from "./pages/Touchbases/schedule";
import TouchbaseTemplates from "./pages/Touchbases/templates";
import NotFound from "./pages/404page";
import Setting from "./pages/Settings/Setting";
import People from "./components/People/People";
import Insights from "./pages/Insights/Insights";
import Support from "./pages/Help&Support/Help&Support";

const Features = React.lazy(() => import("./components/Features"));
const ContactUs = React.lazy(() => import("./components/ContactUs"));
const Integration = React.lazy(() =>
  import("./pages/Integrations/Integration")
);
const JIRAComponent = React.lazy(() =>
  import("./components/Integrations/JIRA")
);

function App() {
  return (
    <ChakraProvider>
      <GoogleOAuthProvider clientId={googleClientId}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/resources"
              element={
                <Suspense fallback={<p>loading...</p>}>
                  <Features />
                </Suspense>
              }
            />
            <Route
              path="/contactus"
              element={
                <Suspense fallback={<p>loading...</p>}>
                  <ContactUs />
                </Suspense>
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route element={<DashboardLayout />}>
              <Route path="/home" element={<DashboardOwner />} />
              <Route path="/company/dashboard" element={<DashboardMain />} />
              <Route
                path="/integrations"
                element={
                  <Suspense fallback={<p>loading...</p>}>
                    <Integration />
                  </Suspense>
                }
              />
              <Route
                path="/integrations/jira"
                element={
                  <Suspense fallback={<p>loading...</p>}>
                    <JIRAComponent />
                  </Suspense>
                }
              />
              <Route path="/company/sessions" element={<AllGoals />} />
              <Route path="/company/touchbase" element={<Touchbase />} />
              <Route path="/company/people" element={<People />} />
              <Route
                path="/touchbase/templates"
                element={<TouchbaseTemplates />}
              />
              <Route
                path="/touchbase/schedule"
                element={<TouchbaseSchedule />}
              />
              <Route
                path="/company/reports/:id"
                element={<ReportDraftPage />}
              />
              <Route path="/company/insight" element={<Insights />} />
              <Route path="/company/billing" element={<BilingPage />} />
              <Route path="/settings" element={<Setting />} />
              <Route path="/help&support" element={<Support />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
}

export default App;
