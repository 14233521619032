import React, { useEffect, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import AssignRoleSection from "./AssignRoleSelection";
import { api } from "../../components/global/api";

const AccessManagement = ({ data, setData, fetchData }) => {
  const dispatch = useDispatch();
  const [editRoleId, setEditRoleId] = useState(null);
  const [editedRoleName, setEditedRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [updates, setUpdates] = useState([]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // const filteredRoles = roles.filter((role) =>
  //   role?.role_name.toLowerCase().includes(searchTerm?.toLowerCase())
  // );

  const [view, setView] = useState("table");
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);

  const handleEditClick = (id, currentName) => {
    setEditRoleId(id);
    setEditedRoleName(currentName);
  };

  const handleInputChange = (e) => {
    setEditedRoleName(e.target.value);
  };

  const handleSave = async (id) => {
    // setRoles(
    //   roles.map((role) =>
    //     role.id === id ? { ...role, role_name: editedRoleName } : role
    //   )
    // );
    // setEditRoleId(null);
    try {
      const res = await api.put(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        role_id: id,
        role_name: editedRoleName,
      });
    } catch (error) {
      console.log(error);
    } finally {
      fetchData();

      setEditRoleId(null);
      setEditedRoleName("");
    }
  };

  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      handleSave(id);
    }
  };

  const handleAssignClick = (role) => {
    setSelectedRole(role);
    setView("assign");
  };

  const handleBackClick = () => {
    setView("table");
  };

  const handleSaveAssign = async (id) => {
    try {
      const serializableUpdates = updates.map((update) => ({
        feature_id: update.feature_id,
        activity_id: update.activity_id,
        activity_status: update.activity_status,
      }));

      const res = await api.put(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        role_id: id,
        updates: serializableUpdates,
      });
      if (res.status === 200) {
        toast.success("Role has been assigned");
      } else {
        toast.error("Please Try Again");
      }
      setUpdates([]);
    } catch (error) {
      console.error("Error assigning role:", error);
      toast.error("Please try again");
    } finally {
      await fetchData();
      setEditRoleId(null);
      setUpdates([]);
      setEditedRoleName("");
      setView("table");
    }
  };

  return (
    <div className="overflow-y-auto">
      <CustomScrollbar>
        <div className="p-4 bg-white rounded-lg w-full space-y-5 font-sans">
          {view === "table" ? (
            <>
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <h1 className="text-2xl font-semibold">
                    Role Level Permission and Privileges
                  </h1>
                  <p className="text-gray-500 text-sm font-semibold">
                    Manage your team members' role level permission and
                    privileges here.
                  </p>
                </div>
                {/* Search Bar with Icon */}
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search members..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="w-full pl-4 pr-10 py-2 border border-gray-300 rounded-lg text-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pt-2 pr-3">
                    <SearchRoundedIcon
                      className="text-gray-400"
                      fontSize="small"
                    />
                  </div>
                </div>
              </div>
              <table className="border border-gray-300 min-w-full rounded-lg overflow-hidden">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 py-4 text-left text-gray-500 font-semibold rounded-tl-lg w-1/2">
                      Role Name
                    </th>
                    <th className="p-2 py-4 text-left text-gray-500 font-semibold rounded-tr-lg w-1/2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((role) => (
                    <tr
                      key={role.role_id}
                      className="border-b border-t border-gray-300"
                    >
                      <td className="p-2 py-5 font-semibold text-sm text-gray-700 w-1/2">
                        {editRoleId === role?.role_id ? (
                          <input
                            type="text"
                            value={editedRoleName}
                            onChange={handleInputChange}
                            disabled={role?.role_name === "Admin"}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleKeyPress(e, role.role_id);
                              }
                            }}
                            className={`p-2 border border-gray-300 rounded-md w-1/2 ${
                              role?.role_name === "Admin" ? "" : ""
                            }`}
                          />
                        ) : (
                          role?.role_name
                        )}
                      </td>
                      <td className="p-2 py-5 font-semibold text-sm text-left text-gray-700 w-1/2">
                        <div className="flex space-x-4 items-center">
                          {editRoleId === role.role_id ? (
                            <>
                              <button
                                onClick={() => handleSave(role?.role_id)}
                                className="text-blue-500"
                              >
                                Save
                              </button>
                              <img
                                src={"/assets/images/assign.png"}
                                alt="Assign"
                                onClick={() => handleAssignClick(role)}
                                className={`cursor-pointer ${
                                  role?.role_name === "Admin"
                                    ? "cursor-not-allowed opacity-50"
                                    : ""
                                }`}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={"/assets/images/assign.png"}
                                alt="Assign"
                                onClick={
                                  role?.role_name === "Admin"
                                    ? undefined
                                    : () => handleAssignClick(role)
                                }
                                className={` ${
                                  role?.role_name === "Admin"
                                    ? "cursor-not-allowed opacity-50"
                                    : "cursor-pointer"
                                }`}
                              />
                              <img
                                src={"/assets/images/edit_icon.png"}
                                alt="Edit"
                                onClick={
                                  role?.role_name === "Admin"
                                    ? undefined
                                    : () =>
                                        handleEditClick(
                                          role?.role_id,
                                          role?.role_name
                                        )
                                }
                                className={` ${
                                  role?.role_name === "Admin"
                                    ? "cursor-not-allowed opacity-50"
                                    : "cursor-pointer"
                                }`}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <AssignRoleSection
                role={selectedRole}
                handleBackClick={handleBackClick}
                handleSaveAssign={handleSaveAssign}
                updates={updates}
                setUpdates={setUpdates}
              />
            </>
          )}
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default AccessManagement;
