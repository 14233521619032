// import { Page, Paragraph, theme } from '@gilbarbara/components';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useAppContext } from './context';
// import Header from './Header';
import Joyride from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { PiStarFour } from 'react-icons/pi';

export default function MultiRouteWrapper() {
    const { setState, state: { run, stepIndex, steps } } = useAppContext();
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    useMount(() => {
        setState({
            steps: [
                {
                    target: '.first_tour',
                    content: (
                        <>
                            <Heading as='h4' size='md' mb={3}>
                                <Flex alignItems={'center'} justifyContent={'center'} gap={2}>
                                    <PiStarFour />
                                    New to iMongu
                                    <PiStarFour />
                                </Flex>
                            </Heading>
                            <Text>
                                I will guide you through the process.
                            </Text>
                        </>
                    ),
                    disableBeacon: true,
                },
                {
                    target: '.second_tour',
                    content: (
                        <>
                            <Heading as='h4' size='md' mb={3}>
                                Step-1: Create a Goal
                            </Heading>
                            <Text>
                                Outline and define each of your goals.
                            </Text>
                        </>
                    ),
                    disableBeacon: true,
                },
                {
                    target: '.third_tour',
                    content: (
                        <>
                            <Heading as='h4' size='md' mb={3}>
                                Step-2: Create a Report
                            </Heading>
                            <Text>
                                Generate reports encompassing all your goals, objectives, and key results.
                            </Text>
                        </>
                    ),
                    disableBeacon: true,
                },
                {
                    target: '.fourth_tour',
                    content: (
                        <>
                            <Heading as='h4' size='md' mb={3}>
                                Step-3: Display an Insight
                            </Heading>
                            <Text>
                                Review all the insights details following the creation of goals, objectives, and key results.
                            </Text>
                        </>
                    ),
                    disableBeacon: true,
                },
                {
                    target: '.fifth_tour',
                    content: (
                        <>
                            <Heading as='h4' size='md' mb={3}>
                                Step-4: Display an Average Report
                            </Heading>
                            <Text>
                                Track the overall progress towards your goals, objectives, and key results.
                            </Text>
                        </>
                    ),
                    disableBeacon: true,
                },
                {
                    target: '.sixth_tour',
                    content: (
                        <>
                            <Heading as='h4' size='md' mb={3}>
                                Step-5: Invite a Team Member
                            </Heading>
                            <Text>
                                Invite individuals to be part of your collaborative team effort.
                            </Text>
                        </>
                    ),
                    disableBeacon: true,
                },
                {
                    target: '.seventh_tour',
                    content: (
                        <>
                            <Heading as='h4' size='md' mb={3}>
                                Step-6: Create a Team
                            </Heading>
                            <Text>
                                Build a team for effective collaboration and success.
                            </Text>
                        </>
                    ),
                    disableBeacon: true,
                },
            ],
        });
    });

    const handleCallback = (data) => {
        const { action, index, lifecycle, type } = data;
        if (type === 'step:after' && (index === 0 || (action === 'prev' && index === 2))) {
            setState({ run: false });
            navigate('/company/sessions');
        } else if (type === 'step:after' && index === 1) {
            if (action === 'next') {
                setState({ run: false });
                navigate('/company/reports');
                // dispatch(handleOpenCreateGoalModal(true));
                // navigate('/home/sessions/1');
            } else {
                navigate('/company/dashboard');
                setState({ run: true, stepIndex: 0 });
            }
        } else if (type === 'step:after' && (index === 1 || (action === 'prev' && index === 3))) {
            setState({ run: false });
            navigate('/company/reports');
        } else if (type === 'step:after' && index === 2) {
            if (action === 'next') {
                setState({ run: false });
                navigate('/company/insight');
            } else {
                navigate('/company/sessions');
                setState({ run: true, stepIndex: 1 });
            }
        } else if (type === 'step:after' && (index === 2 || (action === 'prev' && index === 4))) {
            setState({ run: false });
            navigate('/company/insight');
        } else if (type === 'step:after' && index === 3) {
            if (action === 'next') {
                setState({ run: false });
                navigate('/company/insight/strategic');
            } else {
                navigate('/company/reports');
                setState({ run: true, stepIndex: 2 });
            }
        } else if (type === 'step:after' && (index === 3 || (action === 'prev' && index === 5))) {
            setState({ run: false });
            navigate('/company/insight/strategic');
        } else if (type === 'step:after' && index === 4) {
            if (action === 'next') {
                setState({ run: false });
                navigate('/company/people');
            } else {
                navigate('/company/insight');
                setState({ run: true, stepIndex: 3 });
            }
        } else if (type === 'step:after' && (index === 4 || (action === 'prev' && index === 6))) {
            setState({ run: false });
            navigate('/company/people');
        } else if (type === 'step:after' && index === 5) {
            if (action === 'next') {
                setState({ run: false });
                navigate('/company/people/teams');
            } else {
                navigate('/company/insight/strategic');
                setState({ run: true, stepIndex: 4 });
            }
        } 
        else if (action === 'skip' || action === 'reset' || lifecycle === 'complete') {
            setState({ run: false, stepIndex: 0, tourActive: false });
            localStorage.removeItem('startTour');
            navigate('/company/dashboard')
        }
    };

    return (
        <Box>
            {/* <Header /> */}
            {/* <Outlet /> */}
            <Joyride
                callback={handleCallback}
                continuous
                run={run}
                stepIndex={stepIndex}
                steps={steps}
                hideCloseButton
                showProgress
                disableCloseOnEsc
                disableOverlayClose
                showSkipButton
                // placement={'top, top-start, top-end'}
                // title={'heelo'}
                // isFixed
                // debug
                // disableOverlay
                // disableScrolling
                // disableScrollParentFix
                // floaterProps
                // nonce
                // spotlightClicks
                styles={{
                    options: {
                        arrowColor: '#063F5D',
                        backgroundColor: '#063F5D',
                        // overlayColor: '#698b9d',
                        primaryColor: '#FFB319',
                        textColor: '#FFFFFF',
                        // width: 900,
                        zIndex: 1000,
                    },
                }}
            />
        </Box>
    );
}