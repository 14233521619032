import React, { useState } from "react";
import { Modal, Select } from "antd";
import { reportType } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { baseURL } from "../../utils/config";
import { api }  from "../global/api";

const CreateReportModal = ({ onCancel, fetchData }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    type: reportType[0]?.value,
  });

  const okrSlice = useSelector((state) => state.okr);
const handleCancel = () => {
  setFormData({...formData,name : ""});
  onCancel();
}

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(formData?.name === ""){
      return;
    }

    // return
    try {
      const currentCompany = currentUser?.employees[user?.currentCompany];
      const res = await api.post(baseURL + "/stats/", {
        name: formData?.name,
        type: formData?.type,
        company_id: currentCompany?.company_id,
      });
      fetchData();
      handleCancel();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
          width={550}
          open={okrSlice?.openCreateReport}
          footer={null}
          onCancel={handleCancel}
        >
    <form
      className="w-full flex flex-col py-3 px-3 gap-y-4"
      onSubmit={handleSubmit}
    >
      <div className="w-full flex flex-col items-start gap-y-2">
        <h2 className="text-2xl font-bold text-center">Create Report</h2>
      </div>
      <div className="w-full grid grid-cols-6 gap-x-6 py-2 my-3 gap-y-6 items-center max-h-[350px] overflow-y-auto  ">
        <label htmlFor="name" className="col-span-2 text-sm">
          Report Name
        </label>
        <input
          required
          id="name"
          className="col-span-4 w-full"
          value={formData?.name}
          placeholder="Enter report name"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <label htmlFor="type" className="col-span-2 text-sm">
          Report Type
        </label>
        {/* <input id='type' className='col-span-4' /> */}
        <Select
          id="type"
          className="col-span-4"
          value={formData.type}
          onChange={(e) => setFormData({ ...formData, type: e })}
          placeholder="Select report type"
        >
          {reportType?.map((report) => (
            <Select.Option value={report.value} key={report.value}>
              {report.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className=" flex items-center justify-end gap-x-4">
        <button
          className="px-4 py-2 border rounded-xl border-black hover:text-blue-500 hover:border-blue-500"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border rounded-xl text-white bg-button  hover:bg-button-hover"
          // htmlType="submit"
        >
          Create
        </button>
      </div>
    </form>
    </Modal>
  );
};

export default CreateReportModal;
