import React from "react";
import { Box, Heading, Text, Link, VStack, Divider } from "@chakra-ui/react";
import CustomScrollbar from "../../components/global/CustomScrollBar";

const TermsOfService = () => {
  return (
    <Box className="overflow-y-auto">
      <CustomScrollbar>
        <Box className="mx-auto" bg="white" shadow="md" p={6} rounded="md">
          <Heading as="h2" size="lg" mb={4}>
            Terms of Service
          </Heading>
          <Text mb={4}>
            These Terms of Service govern your use of the website located at{" "}
            <Link color="teal.500" href="https://dev.imongu.com" isExternal>
              https://dev.imongu.com
            </Link>{" "}
            and any related services provided by iMongu Technologies Inc. By
            accessing{" "}
            <Link color="teal.500" href="https://dev.imongu.com" isExternal>
              https://dev.imongu.com
            </Link>
            , you agree to abide by these Terms of Service and to comply with
            all applicable laws and regulations. If you do not agree with these
            Terms of Service, you are prohibited from using or accessing this
            website or using any other services provided by iMongu Technologies
            Inc.
          </Text>
          <Text mb={4}>
            We, iMongu Technologies Inc, reserve the right to review and amend
            any of these Terms of Service at our sole discretion. Upon doing so,
            we will update this page. Any changes to these Terms of Service will
            take effect immediately from the date of publication.
          </Text>
          <Text mb={4}>
            These Terms of Service were last updated on May 28, 2024.
          </Text>

          <Heading as="h2" size="md" mt={8} mb={4}>
            Description of Services
          </Heading>
          <Text mb={4}>
            iMongu Technologies Inc offers a platform that enables effective
            management of Objectives and Key Results (OKRs) through advanced
            tools and technologies. We allow organizations to optimize
            goal-setting, performance management, and alignment with features
            such as AI-driven analytics, automated integration of OKRs and KPIs,
            and enhanced collaboration tools. Our services are designed to
            provide continuous feedback and actionable insights, helping
            organizations to not only meet but exceed their strategic
            objectives.
          </Text>

          <Heading as="h2" size="md" mt={8} mb={4}>
            Limitations of Use
          </Heading>
          <Text mb={4}>
            By using this website, you warrant on behalf of yourself, your
            users, and other parties you represent that you will not:
          </Text>
          <Text mb={4}>
            • modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </Text>
          <Text mb={4}>
            • remove any copyright or other proprietary notations from any
            materials and software on this website;
          </Text>
          <Text mb={4}>
            • transfer the materials to another person or "mirror" the materials
            on any other server;
          </Text>
          <Text mb={4}>
            • knowingly or negligently use this website or any of its associated
            services in a way that abuses or disrupts our networks or any other
            service iMongu Technologies Inc provides;
          </Text>
          <Text mb={4}>
            • use this website or its associated services to transmit or publish
            any harassing, indecent, obscene, fraudulent, or unlawful material;
          </Text>
          <Text mb={4}>
            • use this website or its associated services in violation of any
            applicable laws or regulations;
          </Text>
          <Text mb={4}>
            • use this website in conjunction with sending unauthorized
            advertising or spam;
          </Text>
          <Text mb={4}>
            • harvest, collect, or gather user data without the user’s consent;
            or
          </Text>
          <Text mb={4}>
            • use this website or its associated services in such a way that may
            infringe the privacy, intellectual property rights, or other rights
            of third parties.
          </Text>

          <Heading as="h2" size="md" mt={8} mb={4}>
            Intellectual Property
          </Heading>
          <Text mb={4}>
            The intellectual property in the materials contained in this website
            are owned by or licensed to iMongu Technologies Inc and are
            protected by applicable copyright and trademark law. We grant our
            users permission to download one copy of the materials for personal,
            non-commercial transitory use.
          </Text>
          <Text mb={4}>
            Our platform incorporates AI technology. As such, any content or
            outputs generated by our AI, such as automated OKR reports,
            performance analytics, or any other AI-driven insights, are also
            considered part of the intellectual property owned by or licensed to
            iMongu Technologies Inc. Users should be aware that AI-generated
            content is subject to the same intellectual property rights and
            usage restrictions as other materials provided on our website.
          </Text>
          <Text mb={4}>
            This constitutes the grant of a license, not a transfer of title.
            This license shall automatically terminate if you violate any of
            these restrictions or the Terms of Service, and may be terminated by
            iMongu Technologies Inc at any time.
          </Text>

          <Heading as="h2" size="md" mt={8} mb={4}>
            User-Generated Content
          </Heading>
          <Text mb={4}>
            You retain your intellectual property ownership rights over content
            you submit to us for publication on our website. We will never claim
            ownership of your content, but we do require a license from you in
            order to use it. When you use our website or its associated services
            to post, upload, share, or otherwise transmit content covered by
            intellectual property rights, you grant to us a non-exclusive,
            royalty-free, transferable, sub-licensable, worldwide license to
            use, distribute, modify, run, copy, publicly display, translate, or
            otherwise create derivative works of your content in a manner that
            is consistent with your privacy preferences and our Privacy Policy.
          </Text>
          <Text mb={4}>
            When interacting with our AI-driven services, any data or content
            you provide may be used by our AI to generate new content or
            insights. By using these AI services, you grant us a license to use
            this AI-generated content, which may be based on your original
            input, across our platform and services as part of our ongoing
            enhancement of user experience and service offerings. This includes,
            but is not limited to, the creation of aggregated data or anonymized
            datasets that help improve our algorithms and service outputs.
          </Text>
          <Text mb={4}>
            The license you grant us can be terminated at any time by deleting
            your content or account. However, to the extent that we (or our
            partners) have used your content in connection with commercial or
            sponsored content, the license will continue until the relevant
            commercial or post has been discontinued by us. You also give us
            permission to use your username and other identifying information
            associated with your account in a manner that is consistent with
            your privacy preferences and our Privacy Policy.
          </Text>

          <Heading as="h2" size="md" mt={8} mb={4}>
            Registration and Payments
          </Heading>
          <Text mb={4}>
            To access the services offered by iMongu Technologies Inc, you must
            create an account, ensuring that all provided information is
            accurate and current. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account. This includes managing access and
            permissions within your organization and adhering to legal standards
            pertaining to data privacy and protection. Should there be any
            unauthorized access to your account, it is crucial that you notify
            us immediately. iMongu Technologies Inc is not liable for any loss
            or damage arising from your failure to maintain the security of your
            account information.
          </Text>
          <Text mb={4}>
            iMongu provides various subscription plans to meet the needs of
            organizations, including Basic, Expanded, and Premium tiers. These
            plans are priced on a monthly or yearly basis, with no hidden fees.
            You are free to select, upgrade, or downgrade your subscription
            level as your organizational needs change. When setting up your
            account and selecting a subscription, you will be asked to provide
            financial information for payment purposes. Please note that all
            payment processing is handled by Stripe, our third-party service
            provider. Therefore, iMongu does not retain or directly process your
            financial details. By using Stripe for transactions, you agree to
            comply with their Terms of Service.
          </Text>
          <Text mb={4}>
            It is your responsibility to ensure that your use of the iMongu
            services complies with all applicable laws and regulations. By
            registering an account with iMongu, you accept responsibility for
            the lawful use of the services and for maintaining the integrity and
            security of your account in accordance with these Terms of Service.
          </Text>

          <Heading as="h2" size="md" mt={8} mb={4}>
            Disclaimer and Limitations of Liability
          </Heading>
          <Text mb={4}>
            iMongu Technologies Inc provides its website and services on an "as
            is" and "as available" basis without any warranty of any kind,
            either express or implied. Specifically, iMongu Technologies Inc
            disclaims all warranties, including, but not limited to, warranties
            of merchantability, fitness for a particular purpose,
            non-infringement of intellectual property, or other violation of
            rights
          </Text>
          <Text mb={4}>
            By using the iMongu platform, you acknowledge that such use is at
            your sole risk. iMongu Technologies Inc does not warrant that the
            service will meet your specific requirements, nor will it be
            uninterrupted, timely, secure, or error-free; nor does iMongu make
            any warranty as to the results that may be obtained from the use of
            the service or as to the accuracy or reliability of any information
            obtained through the service, including any AI-generated content or
            insights. The AI-driven functionalities are dependent on various
            factors including but not limited to data input quality and
            algorithmic design, which may affect the appropriateness or accuracy
            of the AI outputs.
          </Text>
          <Text mb={4}>
            In no event will iMongu Technologies Inc or its affiliates, or their
            officers, directors, employees, or agents be liable for any
            indirect, incidental, special, consequential or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from (i) your access to or use
            of or inability to access or use the services; (ii) any conduct or
            content of any third party on the services; (iii) any content
            obtained from the services; and (iv) unauthorized access, use or
            alteration of your transmissions or content, whether based on
            warranty, contract, tort (including negligence) or any other legal
            theory, whether or not we have been informed of the possibility of
            such damage.
          </Text>
          <Text mb={4}>
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you. If you
            are dissatisfied with any portion of the service, or with any of
            these terms, your sole and exclusive remedy is to discontinue using
            the service.
          </Text>

          <Heading as="h2" size="md" mt={8} mb={4}>
            Accuracy of Materials and AI-Generated Content
          </Heading>
          <Text mb={4}>
            The materials and AI-generated content provided on the iMongu
            Technologies Inc website are intended for general information
            purposes only and are not exhaustive. While we strive to provide
            up-to-date and accurate content, iMongu Technologies Inc does not
            guarantee the completeness, accuracy, or usefulness of any materials
            or AI-derived insights on our website, nor the reliability of the
            materials or any information found on linked resources.
          </Text>
          <Text mb={4}>
            AI-generated content, including analytics, reports, and
            recommendations, is produced by algorithms that may reflect biases
            inherent in the data used. Such content is dependent on the quality
            and scope of the input data and the parameters set by the users.
            Therefore, users should treat AI-generated insights as part of a
            broader decision-making process that includes validation from
            additional sources of information and, where appropriate,
            professional advice. Decisions based solely on the information found
            on our website, particularly those derived from AI functionalities,
            are made at your own risk.
          </Text>
          <Text mb={4}>
            We reserve the right to modify the content on our website, including
            AI-generated content, at any time without prior notice. Users are
            responsible for staying informed of any changes by regularly
            reviewing the materials posted on our website. The evolving nature
            of AI technologies and methods means that the AI-generated outputs
            may not always encapsulate the most current developments or emerging
            trends in the field of OKR management.
          </Text>
          <Text mb={4}>
            In using our AI features, you acknowledge that while AI can provide
            valuable support, it is not infallible and should be used as a tool
            to augment, not replace, human expertise and judgment. As the
            materials and tools on the website are not tailored specifically to
            individual needs, they may not cover all aspects relevant to your
            particular situation.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Modifications to the Service and Prices
          </Heading>
          <Text mb={4}>
            We reserve the right to modify or discontinue any aspect of our
            services at any time, with or without notice to the users. Such
            modifications may include changes to the feature set, user
            interface, and service capabilities. Similarly, we may adjust
            pricing for our services as necessary; such changes will be
            communicated to the users at least 30 days in advance through
            official channels such as the service website or direct
            communications. Users are advised to review the Terms of Service
            periodically to stay informed of any changes.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Additional Tools
          </Heading>
          <Text mb={4}>
            Please note that our website may include third-party tools which are
            outside of our control. The access to said tools is provided on an
            'as is' basis with no warranties, expressed or implied, and without
            any endorsement. We are not liable for the use of the third-party
            tools provided on our website. You are solely responsible for any
            risks associated with using said tools, we recommend reading the
            relevant third-party provider’s Terms of Service. iMongu
            Technologies Inc has the right to offer new third-party tools in the
            future.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Links
          </Heading>
          <Text mb={4}>
            iMongu Technologies Inc has not reviewed all of the sites linked to
            its website and is not responsible for the contents of any such
            linked site. The inclusion of any link does not imply endorsement,
            approval or control by iMongu Technologies Inc of the site. Use of
            any such linked site is at your own risk and we strongly advise you
            make your own investigations with respect to the suitability of
            those sites.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Inaccuracies and Mistakes
          </Heading>
          <Text mb={4}>
            Please note that any content available on our website may contain
            typographical errors, inaccuracies, or mistakes related to the
            service’s description, pricing, promotion, and availability. We have
            the right to cancel or update any existing services should any
            information provided by us be proven inaccurate. Said information
            can be amended at any time without prior notice. Please note that we
            are not obligated to clarify any of the information related to our
            website and service, including pricing information, except as
            required by law.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Indemnification
          </Heading>
          <Text mb={4}>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            your Contributions; (2) use of the website; (3) breach of these
            Terms of Service; (4) any breach of your representations and
            warranties set forth in these Terms of Service; (5) your violation
            of the rights of a third party, including but not limited to
            intellectual property rights; or (6) any overt harmful act toward
            any other user of the website with whom you connected via the
            website.
          </Text>
          <Text mb={4}>
            Notwithstanding the foregoing, we reserve the right, at your
            expense, to assume the exclusive defense and control of any matter
            for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </Text>

          <Heading as="h4" size="sm" mt={6} mb={4}>
            Right to Terminate
          </Heading>
          <Text mb={4}>
            Users may terminate their service with iMongu at any time in
            accordance with the termination procedures specified on the iMongu
            website. iMongu also reserves the right to terminate or suspend user
            accounts due to non-compliance with the terms of service or extended
            periods of inactivity. Upon termination, user data will be deleted
            from our servers, except where retention is required by law. Users
            are advised to ensure proper data backup and retrieval arrangements
            before account closure.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Severance
          </Heading>
          <Text mb={4}>
            Any term of these Terms of Service which is wholly or partially void
            or unenforceable is severed to the extent that it is void or
            unenforceable. The validity of the remainder of these Terms of
            Service is not affected.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Entire Agreement
          </Heading>
          <Text mb={4}>
            These Terms of Service and any policies or operating rules posted by
            us on the website or with respect to the website constitute the
            entire agreement and understanding between you and us. Our failure
            to exercise or enforce any right or provision of these Terms of
            Service shall not operate as a waiver of such right or provision.
            These Terms of Service operate to the fullest extent permissible by
            law. We may assign any or all of our rights and obligations to
            others at any time. We shall not be responsible or liable for any
            loss, damage, delay, or failure to act caused by any cause beyond
            our reasonable control.
          </Text>

          <Text mb={4}>
            If any provision or part of a provision of these Terms of Service is
            determined to be unlawful, void, or unenforceable, that provision or
            part of the provision is deemed severable from these Terms of
            Service and does not affect the validity and enforceability of any
            remaining provisions. There is no joint venture, partnership,
            employment, or agency relationship created between you and us as a
            result of these Terms of Service or use of the website. You agree
            that these Terms of Service will not be construed against us by
            virtue of having drafted them. You hereby waive any and all defenses
            you may have based on the electronic form of these Terms of Service
            and the lack of signing by the parties hereto to execute these Terms
            of Service.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Governing Law
          </Heading>
          <Text mb={4}>
            These Terms of Service are governed by and construed in accordance
            with the laws of the United States. You irrevocably submit to the
            exclusive jurisdiction of the courts in that location.
          </Text>
        </Box>
      </CustomScrollbar>
    </Box>
  );
};

export default TermsOfService;
