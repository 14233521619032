import React, { useEffect, useState } from "react";
import ReportTable from "../../components/Report/ReportTable";
import CreateReportModal from "../../components/Report/CreateReportModal";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../utils/config";
import { handleUpdateDraftSlice } from "../../stores/slices/draftSlice";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import { useNavigate } from "react-router-dom";
import { handleCloseCreateReport } from "../../stores/slices/okrSlice";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { api } from "../../components/global/api";

const ReportDrafts = () => {
  const navigate = useNavigate();
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      // setTimeout(() => {
      // setLoader(false);
      setState({ run: true, stepIndex: 2 });
      // }, 1200);
    }
  });

  const [draftReports, setDraftReports] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state.user);
  const drafts = useSelector((state) => state.drafts);
  const currentPlan = user?.currentPlan;
  if (currentPlan?.plan_name === "Free" && !currentPlan?.free_trial_status) {
    navigate("/company/billing");
  }

  const handleClose = () => {
    dispatch(handleCloseCreateReport());
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const currentCompany = currentUser?.employees[user?.currentCompany];
      const res = await api.get(baseURL + "/stats/", {
        company_id: currentCompany?.company_id,
      });
      setDraftReports(res.data || []);
      dispatch(handleUpdateDraftSlice(res.data || []));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    setDraftReports(drafts);
  }, []);

  const handleDeleteReport = async (report_id) => {
    try {
      setLoading(true);
      const res = await api.delete(baseURL + "/stats/", {
        report_id,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  return (
    <div className="relative h-full ">
      <div className=" h-full bg-white rounded-lg p-4">
        <CustomScrollbar>
          <div className="flex flex-col w-full h-full ">
            <span className="flex gap-x-2 items-center">
              <svg
                width="15"
                height="13"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="9" height="3" rx="1.5" fill="#0098CA" />
                <rect y="5" width="11" height="3" rx="1.5" fill="#1AA2CF" />
                <rect y="10" width="15" height="3" rx="1.5" fill="#0098CA" />
              </svg>
              <p className="text-basic font-medium text-2xl">Reports List</p>
            </span>
            <div className="py-4">
              {!draftReports || loading ? (
                <div className=" h-full  flex justify-center items-center mx-auto">
                  <img src="/assets/images/Group 3.svg" />
                </div>
              ) : (
                <ReportTable
                  draftReports={draftReports}
                  handleDeleteReport={handleDeleteReport}
                />
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      <CreateReportModal onCancel={handleClose} fetchData={fetchData} />
    </div>
  );
};

export default ReportDrafts;
